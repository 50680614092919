import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import Modal from '../../../../components/core/Modal/Modal';
import { useDeviceType } from '../../../../common/Device/Device';
import Button from '../../../../components/core/Button/Button';
import { FSA, FSA_IMAGES } from '../../../../common/Constants';
import phoneApp from '../../../../assets/svg/phoneApp.svg';
import deliveryMan from '../../../../assets/svg/deliveryMan.svg';
import arrow from '../../../../assets/svg/arrow.svg';

import styles from './PopUpModal.css';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

const PopUpModal = ({ isOpen, closeModal, onGetStartedClicked, isLearnMore }) => {
    const deviceType = useDeviceType();
    const isMobile = deviceType === 'mobile' || deviceType === 'tablet';
    const [currentStep, setCurrentStep] = useState(0);

    const onModalClose = () => {
        setCurrentStep(0);
        closeModal();
    };

    const onGetStartedClickedHandler = () => {
        onGetStartedClicked();
        onModalClose();
    };

    const localization = useSelector(state => state.pageContent.localization);
    const components = useSelector(state => state.pageContent.components);
    const imageComponents = components.find(component => component.uid === FSA_IMAGES.DELIVERY_PARTNERS_IMAGE);
    const deliveryPartners =
        imageComponents &&
        imageComponents.childComponents &&
        imageComponents.childComponents.map(image => {
            let partner = '';
            try {
                partner = new URL(image.urlLink).hostname;
            } catch (error) {
                console.error(error);
            }

            return { imgUrl: image.url, partner, urlLink: image.urlLink };
        });

    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);

    const modalData = isLearnMore
        ? {
              text1: localization[FSA.SECTION_TWO_POPUP_ONE_TEXT_1],
              text2: localization[FSA.SECTION_TWO_POPUP_ONE_TEXT_2],
              text3: localization[FSA.SECTION_TWO_POPUP_ONE_TEXT_3],
              text4: localization[FSA.SECTION_TWO_POPUP_ONE_TEXT_4],
              text5: localization[FSA.SECTION_TWO_POPUP_ONE_TEXT_5],
              text6: localization[FSA.SECTION_TWO_POPUP_ONE_TEXT_6],
              text7: localization[FSA.SECTION_TWO_POPUP_ONE_TEXT_7],
              text8: localization[FSA.SECTION_TWO_POPUP_ONE_TEXT_8],
              text9: localization[FSA.SECTION_TWO_POPUP_ONE_TEXT_9],
              text10: localization[FSA.SECTION_TWO_POPUP_ONE_TEXT_10],
              text11: localization[FSA.SECTION_TWO_POPUP_ONE_TEXT_11],
              text12: localization[FSA.SECTION_TWO_POPUP_ONE_TEXT_12]
          }
        : {
              text1: localization[FSA.SECTION_TWO_POPUP_TWO_TEXT_1],
              text2: localization[FSA.SECTION_TWO_POPUP_TWO_TEXT_2],
              extraText2: localization[FSA.SECTION_TWO_POPUP_TWO_TEXT_3],
              text3: localization[FSA.SECTION_TWO_POPUP_TWO_TEXT_4],
              text4: localization[FSA.SECTION_TWO_POPUP_TWO_TEXT_5],
              text5: localization[FSA.SECTION_TWO_POPUP_TWO_TEXT_6],
              text6: localization[FSA.SECTION_TWO_POPUP_TWO_TEXT_7],
              text7: localization[FSA.SECTION_TWO_POPUP_TWO_TEXT_8],
              text8: localization[FSA.SECTION_TWO_POPUP_TWO_TEXT_9],
              text9: localization[FSA.SECTION_TWO_POPUP_TWO_TEXT_10],
              text10: localization[FSA.SECTION_TWO_POPUP_TWO_TEXT_11],
              text11: localization[FSA.SECTION_TWO_POPUP_TWO_TEXT_12],
              text12: localization[FSA.SECTION_TWO_POPUP_TWO_TEXT_13]
          };

    const getStepperClassName = currentStep => {
        switch (currentStep) {
            case 0:
                return 'stepOne';
            case 1:
                return 'stepTwo';
            default:
                return 'stepThree';
        }
    };
    const title = (
        <div className={cx('stepCounter', getStepperClassName(currentStep))}>
            {isMobile && `${currentStep + 1} of 3`}
        </div>
    );

    return (
        <Modal
            defaultCloseBtn
            onClose={onModalClose}
            showModal={isOpen}
            modalTheme={cx('modalTheme')}
            themeContent={cx('modalContent')}
            themeClose={cx('modalCloseButton')}
            title={isMobile && title}
            modalTitleText={cx('modalTitleText')}
        >
            {(!isMobile || currentStep === 0) && (
                <div className={cx('step1Container')}>
                    <h3>{modalData.text1}</h3>
                    <p>{modalData.text2}</p>
                    {modalData.extraText2 && <p>{modalData.extraText2}</p>}
                </div>
            )}
            {(!isMobile || currentStep === 1) && (
                <div className={cx('step2Container')}>
                    <h3>{modalData.text3}</h3>
                    <div className={cx('step2Inner')}>
                        <div>
                            <img src={phoneApp} alt='phone app' />
                            <h3>{modalData.text4}</h3>
                            <ul>
                                <li>{modalData.text5}</li>
                                <li>{modalData.text6}</li>
                                <li>{modalData.text7}</li>
                            </ul>
                        </div>
                        <div>
                            <img src={phoneApp} alt='phone app icon' />
                            <img src={deliveryMan} alt='delivery man icon' />
                            <h3>{modalData.text8}</h3>
                            <ul>
                                <li>{modalData.text9}</li>
                                <li>{modalData.text10}</li>
                                <li>{modalData.text11}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            {(!isMobile || currentStep === 2) && (
                <div className={cx('step3Container', isLearnMore && 'isLearnMore')}>
                    <h3>{modalData.text12}</h3>
                    {!isLearnMore && (
                        <div
                            className={cx('imagesContainer')}
                            style={{
                                gridTemplateColumns:
                                    deliveryPartners && deliveryPartners.length < 3
                                        ? `repeat(${deliveryPartners.length}, 1fr)`
                                        : 'repeat(3, 1fr)'
                            }}
                        >
                            {deliveryPartners &&
                                deliveryPartners.map(({ imgUrl, partner, urlLink }, index) => {
                                    return (
                                        <img
                                            key={index}
                                            role='presentation'
                                            src={`${assetBaseUrl}${imgUrl}`}
                                            alt='delivery partner'
                                            onKeyDown={() => {
                                                // please place this buttonClickEvent in the handler if images are changed to links
                                                try {
                                                    buttonClickEvent(
                                                        buttonClickEventConfigs.fsa.actions.getStarted,
                                                        buttonClickEventConfigs.fsa.labels.getStartedPrefix + partner,
                                                        buttonClickEventConfigs.fsa.categories.tipsNTricks
                                                    );
                                                } catch (ex) {
                                                    console.error(ex);
                                                } finally {
                                                    window.open(urlLink, '_blank');
                                                }
                                            }}
                                            onClick={() => {
                                                // please place this buttonClickEvent in the handler if images are changed to links
                                                try {
                                                    buttonClickEvent(
                                                        buttonClickEventConfigs.fsa.actions.getStarted,
                                                        buttonClickEventConfigs.fsa.labels.getStartedPrefix + partner,
                                                        buttonClickEventConfigs.fsa.categories.tipsNTricks
                                                    );
                                                } catch (ex) {
                                                    console.error(ex);
                                                } finally {
                                                    window.open(urlLink, '_blank');
                                                }
                                            }}
                                        />
                                    );
                                })}
                        </div>
                    )}
                    {isLearnMore && !isMobile && (
                        <Button
                            type='button'
                            size={cx('Md')}
                            className={cx('button', 'arrowButton')}
                            onClick={() => {
                                onGetStartedClickedHandler();
                                try {
                                    buttonClickEvent(
                                        buttonClickEventConfigs.fsa.actions.learnMore,
                                        buttonClickEventConfigs.fsa.labels.learnMoreAnchorTopTips,
                                        buttonClickEventConfigs.fsa.categories.tipsNTricks
                                    );
                                } catch (ex) {
                                    console.error(ex);
                                }
                            }}
                        >
                            <img className={cx('arrowIcon')} src={arrow} alt='arrow icon' />
                        </Button>
                    )}
                </div>
            )}
            {isMobile && (
                <div className={cx('buttonsContainer')}>
                    <Button
                        type='button'
                        size={cx('Md')}
                        className={cx('button', 'backButton')}
                        onClick={() => setCurrentStep(currentStep - 1)}
                        isDisabled={currentStep === 0}
                    >
                        {localization[FSA.SECTION_TWO_TEXT_22]}
                    </Button>
                    <Button
                        type='button'
                        size={cx('Md')}
                        className={cx('button')}
                        onClick={currentStep !== 2 ? () => setCurrentStep(currentStep + 1) : onGetStartedClickedHandler}
                    >
                        {currentStep !== 2
                            ? localization[FSA.SECTION_TWO_TEXT_23]
                            : localization[FSA.SECTION_SEVEN_TEXT_3]}
                    </Button>
                </div>
            )}
        </Modal>
    );
};

export default PopUpModal;
