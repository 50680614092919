import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import _get from 'lodash/get';
import classNames from 'classnames/bind';
import { PAGE_ID, MY_ACCOUNT_SETTINGS_TABS, ACCOUNT_SETTINGS_L10N } from '../../../common/Constants';
import Layout from '../../../components/site/Layout/Layout';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import { browserHistory } from 'react-router';
import { getPageContent } from '../../../actions/PageContentAction';

import * as styles from './AccountSettings.css';
import AccountData from '../AccountData/AccountData';
import UpdatePassword from '../UpdatePassword/UpdatePassword';
import UserMaintenance from '../UserMaintenance/UserMaintenance';
const cx = classNames.bind(styles);

export const AccountSettings = props => {
    const dispatch = useDispatch();

    const [currentSectionTab, setCurrentSectionTab] = useState('');
    const components = useSelector(state => state.pageContent.components);
    const tabs =  React.useMemo(()=> {
        const uidsComponents = components.map((c)=> c?.uid || "")
        const allowedTabs = Object.keys(MY_ACCOUNT_SETTINGS_TABS)
        .filter(key => uidsComponents.includes(MY_ACCOUNT_SETTINGS_TABS[key].uid))
        .reduce((obj, key) => {
          obj[key] = key;
          return obj;
        }, {});

       return allowedTabs
    }, [components])
    const localization = useSelector(state => state.pageContent.localization);

    const breadcrumbs = useSelector(state => state.pageContent.breadcrumbs)
    React.useEffect(() => {
        if (props?.params?.section) {
            setCurrentSectionTab(tabs[props?.params?.section]);
        }
    }, [props?.params, tabs]);

    

    const isPasswordTab = React.useMemo(()=> {
        return currentSectionTab === MY_ACCOUNT_SETTINGS_TABS.updatePassword.name
    }, [currentSectionTab])

    React.useEffect(() => {
        
        dispatch(getPageContent({ pageId: PAGE_ID.MY_SETTINGS, isSkipLoader: !isPasswordTab }));
    },[isPasswordTab])
    
    // missing tabs localizations
    const findLocalizationForTabs = key => {
        if(!localization) return ''
        switch (key) {
            case 'personalData':
                return localization[ACCOUNT_SETTINGS_L10N?.PERSONAL_DATA_TAB];
            case 'updatePassword':
                return localization[ACCOUNT_SETTINGS_L10N?.EDIT_PASSWORD_TAB];
            case 'customerFactSheet':
                return localization[ACCOUNT_SETTINGS_L10N?.CUSTOMER_FACT_SHEET_TAB];
            case 'companyData':
                return localization[ACCOUNT_SETTINGS_L10N?.COMPANY_DATA_TAB];
            case 'userManagement':
                return localization[ACCOUNT_SETTINGS_L10N?.USER_MANAGEMENT_TAB];
            default:
                return '';
        }
    };

    const panels = React.useMemo(()=> {
        const uidsComponents = components.map((c)=> c?.uid || "")
      return (
        <>
        {uidsComponents.includes(MY_ACCOUNT_SETTINGS_TABS.personalData.uid) && (
              <TabPanel className={cx('react-tabs__tab-panel')}>
              <AccountData embeded='personaldata' />
          </TabPanel>
        )}
          {uidsComponents.includes(MY_ACCOUNT_SETTINGS_TABS.updatePassword.uid) && (
             <TabPanel className={cx('react-tabs__tab-panel')}>
             <UpdatePassword embeded="account-settings" />
         </TabPanel>
        )}
                   
                    {uidsComponents.includes(MY_ACCOUNT_SETTINGS_TABS.customerFactSheet.uid) && (
              <TabPanel className={cx('react-tabs__tab-panel')}>
              <AccountData embeded='factsheet' />
          </TabPanel>
        )}
                  
                    {uidsComponents.includes(MY_ACCOUNT_SETTINGS_TABS.companyData.uid) && (
            <TabPanel className={cx('react-tabs__tab-panel')}>
            <AccountData embeded='companydata' />
        </TabPanel>
        )}
                    
                    {uidsComponents.includes(MY_ACCOUNT_SETTINGS_TABS.userManagement.uid) && (
             <TabPanel className={cx('react-tabs__tab-panel')}>
             <UserMaintenance embeded />
         </TabPanel>
        )}
                    
        </>
      )
    }, [components]) 
    return (
        <Layout showDashboardHeader showOutlet pageId={PAGE_ID.ACCOUNT_SETTINGS}>
            <div className={cx('AccountSettings')}>
                <BreadCrumbs breadcrumbValues={breadcrumbs} />
                <div className={cx('title-holder')}>
                    <h2 automation-id='at-order-information-title-text' className={cx('title')}>
                        <span>{localization && localization[ACCOUNT_SETTINGS_L10N.TITLE]}</span>
                    </h2>
                    {/* <p automation-id='at-order-information-subtitle-text' className={cx('subtitle')}>
                        {localization && localization[ACCOUNT_SETTINGS_L10N.SUBTITLE]}
                    </p> */}
                </div>
                <div className={cx('tabs-holder')}>
                    <Tabs
                        className={cx('react-tabs')}
                        selectedTabClassName='AccountSettings-react-tabs__tab--selected'
                        selectedTabPanelClassName='AccountSettings-react-tabs__tab-panel--selected'
                        selectedIndex={Object.values(tabs).findIndex(
                            tab => tab === currentSectionTab
                        )}
                        onSelect={index => {
                            browserHistory.push(`/account/settings/${Object.keys(tabs)[index]}`);
                        }}
                    >
                        <TabList className={cx('react-tabs__tab-list')}>
                            {Object.values(tabs).map(tabName => {
                                return (
                                    <Tab key={tabName} className={cx('react-tabs__tab')}>
                                        {findLocalizationForTabs(tabName)}
                                    </Tab>
                                );
                            })}
                        </TabList>

                        {panels}
                    </Tabs>
                </div>
            </div>
        </Layout>
    );
};

export default AccountSettings;
