import React, { useState } from 'react';
import * as styles from './AccountShoppingList.css';
import Layout from '../../../components/site/Layout/Layout';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import _get from 'lodash/get';
import { browserHistory } from 'react-router';

import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { getPageContent } from '../../../actions/PageContentAction';
import { SHOPPING_LIST_SECTION_TABS, MY_ACCOUNT_L10N, PAGE_ID, ACCOUNT_TABS_RESTRICTIVE } from '../../../common/Constants';
const cx = classNames.bind(styles);
import MyAccountMock from '../../../mock/AccountShoppingList/AccountShoppingList.mock.json';
import { isBdaaActive, mainPartnerId, mainPartnerCode } from '../../../common/UserManager/UserManager';
import { getBdaaSuggestion } from '../../../actions/ProductListAction';
import { getListFavorites } from '../../../actions/FavouriteAction';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AccountShoppingFavorites } from './AccountShoppingFavorites/AccountShoppingFavorites';
import { AccountShoppingBdaa } from './AccountShoppingBdaa/AccountShoppingBdaa';
import { AccountShoppingSavedCart } from './AccountShoppingSavedCart/AccountShoppingSavedCart';
import { AccountShoppingQuickOrder } from './AccountShoppingQuickOrder/AccountShoppingQuickOrder';



const sections = Object.keys(SHOPPING_LIST_SECTION_TABS).map((key, i) => {
    return {
        type: key,
        title: SHOPPING_LIST_SECTION_TABS[key]
    };
});
export const ShoppingList = props => {
    const [currentSectionTab, setCurrentSectionTab] = useState(
        ''
    );
    const dispatch = useDispatch();
    const bdaaSuggestion = useSelector(state => state.products.bdaaSuggestion);


    const components = useSelector(state => state.pageContent.components);
   
    const localization = useSelector(state => state.pageContent.localization);

    const breadcrumbs = useSelector(state => state.pageContent.breadcrumbs)
    const isBdaaActiveVar = isBdaaActive();
    const mainPartnerIdVar = mainPartnerId();
const mainPartnerCodeVar = mainPartnerCode();

    const getPageContentFunc = React.useCallback(() => {
        dispatch(getPageContent({ pageId: PAGE_ID.SHOPPING_LIST }));
        if (isBdaaActiveVar) {
            dispatch(getBdaaSuggestion({ data: { countryCode: mainPartnerCodeVar, customerId: mainPartnerIdVar } }));
        }
        
    }, [dispatch, getListFavorites, isBdaaActiveVar, mainPartnerCodeVar, mainPartnerIdVar, getPageContent]);

    React.useEffect(() => {
        getPageContentFunc();
    }, [getPageContentFunc]);

    React.useEffect(() => {
        if (props?.params?.section) {
            setCurrentSectionTab(SHOPPING_LIST_SECTION_TABS[props?.params?.section]);
        }
    }, [props?.params]);

    const isDesktop = useSelector(state => state.context.deviceType.isDesktop);

    
    const filteredSections = React.useMemo(()=> {
        const uidsComponents = components.map((c)=> c?.uid || "")
        let fsections = sections
        if (!isDesktop) {
            fsections = fsections.filter(section => section.title !== SHOPPING_LIST_SECTION_TABS.quickOrder);
        }
    
        if (!isBdaaActiveVar || !bdaaSuggestion || bdaaSuggestion?.length === 0) {
            fsections = fsections.filter(section => section.title !== SHOPPING_LIST_SECTION_TABS.smartOrders);
        }
        if(!uidsComponents.includes(ACCOUNT_TABS_RESTRICTIVE.MY_FAVOURITES_TAB)){
            fsections = fsections.filter(section => section.title !== SHOPPING_LIST_SECTION_TABS.favorites);
        }
        if(!uidsComponents.includes(ACCOUNT_TABS_RESTRICTIVE.MY_RECOMMENDATIONS_TAB)){
            fsections = fsections.filter(section => section.title !== SHOPPING_LIST_SECTION_TABS.smartOrders);
        }
        if(!uidsComponents.includes(ACCOUNT_TABS_RESTRICTIVE.MY_SAVED_CARTS_TAB)){
            fsections = fsections.filter(section => section.title !== SHOPPING_LIST_SECTION_TABS.savedCarts);
        }
        if(!uidsComponents.includes(ACCOUNT_TABS_RESTRICTIVE.QUICK_ORDER_TAB)){
            fsections = fsections.filter(section => section.title !== SHOPPING_LIST_SECTION_TABS.quickOrder);
        }
        
        return fsections
    }, [sections, components, bdaaSuggestion, isBdaaActiveVar, isDesktop])
   
  
    const findLocalizationForTabs = React.useCallback(key => {
        const commonLocalizationTabs = {
            favorites: localization && localization[MY_ACCOUNT_L10N.SHOPPING_LIST_TAB_FAVORITES],
            smartOrders: localization && localization[MY_ACCOUNT_L10N.SHOPPING_LIST_TAB_RECOMMENDATIONS],
            savedCarts: localization && localization[MY_ACCOUNT_L10N.SHOPPING_LIST_SAVED_CARTS],
            quickOrder: localization && localization[MY_ACCOUNT_L10N.SHOPPING_LIST_QUICK_ORDER]
        };
        try {
            return commonLocalizationTabs[key];
        } catch (error) {
            return '';
        }
    }, [localization]);

    React.useEffect(() => {
        if (props?.params?.section) {
            setCurrentSectionTab(SHOPPING_LIST_SECTION_TABS[props?.params?.section]);
        }
    }, [props?.params]);

    return (
        <Layout showDashboardHeader showOutlet>
            <div className={cx('AccountShoppingList')}>
                <BreadCrumbs breadcrumbValues={breadcrumbs} />
                <div className={cx('commonWidget', 'clearfix')} >
                    <h2 className={cx('headlineContainer')}>
                        <span className={cx('pageTitle')}>{localization && localization[MY_ACCOUNT_L10N.SHOPPING_LIST_TITLE]}</span>
                    </h2>
                    {/* <h3 className={cx('subText')}>{localization && localization[MY_ACCOUNT_L10N.SHOPPING_LIST_SUBTITLE]}</h3> */}
                </div>
                <div className={cx('tabs-holder')}>
                    <Tabs
                        className={cx('react-tabs')}
                        selectedTabClassName='AccountShoppingList-react-tabs__tab--selected'
                        selectedTabPanelClassName='AccountShoppingList-react-tabs__tab-panel--selected'
                        selectedIndex={Object.values(SHOPPING_LIST_SECTION_TABS).findIndex(
                            tab => tab === currentSectionTab
                        )}
                        onSelect={index => {
                            browserHistory.push(`/account/shoppingList/${Object.keys(SHOPPING_LIST_SECTION_TABS)[index]}`);
                        }}
                    >
                        <TabList className={cx('react-tabs__tab-list')}>
                            {Object.values(SHOPPING_LIST_SECTION_TABS).map(tabName => {
                                
                                const findTab = filteredSections.find((fs)=> fs.type === tabName)
                            
                                return (
                                    <Tab key={tabName} className={cx('react-tabs__tab', findTab ? '' : 'hideTab'  )}>
                                        {findLocalizationForTabs(tabName)}
                                    </Tab>
                                );
                            })}
                        </TabList>

                        <TabPanel className={cx('react-tabs__tab-panel')}><AccountShoppingFavorites /></TabPanel>
                        <TabPanel className={cx('react-tabs__tab-panel')}><AccountShoppingBdaa /></TabPanel>
                        <TabPanel className={cx('react-tabs__tab-panel')}><AccountShoppingSavedCart /></TabPanel>
                        <TabPanel className={cx('react-tabs__tab-panel')}><AccountShoppingQuickOrder /></TabPanel>
                     
                    </Tabs>
                </div>
            </div>
        </Layout>
    );
};
