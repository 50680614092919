import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import Modal from '../../core/Modal/Modal';
import Button from '../../core/Button/Button';
import OrderTemplateModalContent from './OrderTemplateModalContent/OrderTemplateModalContent';
import { getOrderTemplateDetails } from '../../../actions/OrderTemplateAction';
import * as styles from './AddToOrderTemplate.css';

const cx = classNames.bind(styles);

export class AddToOrderTemplate extends Component {
    static propTypes = {
        automationId: PropTypes.string,
        basicProductDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        attBtnText: PropTypes.string,
        plpCustomStyle: PropTypes.string,
        showTemplate: PropTypes.bool,
        cardImg: PropTypes.string
    };

    static defaultProps = {
        automationId: '',
        basicProductDetails: [],
        attBtnText: '',
        plpCustomStyle: '',
        showTemplate: true,
        cardImg: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    modalOnClose = () => {
        this.setState({ showModal: false });
    };

    handleAddToOrderTemplate = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { actions, orderTemplates } = this.props;
        _isEmpty(orderTemplates) && actions.getOrderTemplateDetails({ isAllTemplates: true });
        this.setState({ showModal: true });
    };

    render() {
        const {
            automationId,
            basicProductDetails,
            plpCustomStyle,
            attBtnText,
            showTemplate,
            addToTempBtn,
            modalTitle,
            cardImg,
            pdpBtn
        } = this.props;
        const { showModal } = this.state;
        return (
            <div automation-id='at-order-template-overview-popup' onClick={(e)=>{e.preventDefault(); e.stopPropagation()}} className={cx('addtoOrderWrapper')}>
                <div className={cx('addToTemplateIcon')}>
                    {pdpBtn && (
                           <button
                           automation-id={automationId}
                           type='button'
                           className={cx('templatePdpBtn')}
                           onClick={this.handleAddToOrderTemplate}
                       >
                           {attBtnText}
                           <img src='/assets/images/add_card.svg' alt='add_card' className={cx(cardImg)} />
                       </button>
                    )}
                    {!pdpBtn && showTemplate && (
                        <button
                            automation-id={automationId}
                            type='button'
                            className={cx('templateIcon', plpCustomStyle)}
                            onClick={this.handleAddToOrderTemplate}
                        >
                            {/* <span className={cx('visibleSm')}>{attBtnText}</span> */}
                            <img src='/assets/images/add_card.svg' alt='add_card' className={cx(cardImg)} />
                        </button>
                    )} 
                    {!pdpBtn && !showTemplate &&  (
                        <button
                            automationId='at-order-button'
                            type='button'
                            className={cx('templateIcon','addAllBtn')}
                            onClick={this.handleAddToOrderTemplate}
                        >
                            <img src='/assets/images/add_card.svg' alt='add_card' className={cx(cardImg)} />
                            {addToTempBtn}
                        </button>
                    ) }
                </div>
                {showModal && (
                    <Modal
                        showModal={showModal}
                        modalTheme={cx('themeModal')}
                        modalTitleTheme={cx('modalTitleTheme')}
                        themeContent={cx('themeContent')}
                        themeClose={cx('themeClose')}
                        themeModalBlock={cx('themeModalBlock')}
                        title={modalTitle}
                        titleAutomationId='at-add-to-order-template-title-text'
                        onClose={this.modalOnClose}
                    >
                        <OrderTemplateModalContent
                            customOrderTemplate={cx('customOrderTemplate')}
                            basicProductDetails={basicProductDetails}
                            modalClose={this.modalOnClose}
                        />
                    </Modal>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    orderTemplates: _get(state, 'orderTemplate.allTemplateList')
});

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators({ getOrderTemplateDetails }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToOrderTemplate);
