/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import classNames from 'classnames/bind';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import _isEmpty from 'lodash/isEmpty';
import Icon from '../../core/Icon/Icon';
import QtyInput from '../../core/QtyInput/QtyInput';
import { addToCart, addToCartSapUpdate } from '../../../actions/CartAction';
import * as styles from './AddToCart.css';
import { addToCartEventGA4, removeFromCartEventGA4, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';
import {
    MAX_PRODUCT_QUANTITY,
    PAGE_ID,
    PLP_L10N,
    DATA_LAYER_LISTS,
    CART_ORDER_STATUSES
} from '../../../common/Constants';
import { store } from '../../../../__runtime/redux/Store';
import Button from '../../core/Button/Button';
import { getProductPrice } from '../../../service/DataLayerFilter';

const cx = classNames.bind(styles);

const AddToCartButton = props => {
    const {
        automationId,
        customAddtoCartStyle,
        themeAddCartIcon,
        productInfo,
        isMultiCart,
        iconName,
        handleAddToCart,
        buttonType = 'Ordinary',
        stockFlag,
        stockNotified,
        addToCartNotifyButton,
        addToCartNotifiedButton,
        blockAddToCartEnabled,
        maxValue,
        autoHeight,
        deviceType,
        isFromProductCards,
        isFromProductCardList
    } = props;
    const outOfStock = stockFlag === 'OUT_OF_STOCK';
    let {buttonValue} = props;
    let customIconName = iconName;
    let customButtonType = buttonType;
    let additionalButtonStyle = null;

    //If the stockNotified is null that means the notification feature is turned off
    const notificationsEnabled = stockNotified !== (null || undefined);
    let buttonStatus = CART_ORDER_STATUSES.SHOW_TO_ORDER;

    if (outOfStock && !notificationsEnabled && blockAddToCartEnabled) {
        buttonStatus = CART_ORDER_STATUSES.HIDE;
    }
    if (outOfStock && notificationsEnabled && !stockNotified) {
        buttonStatus = CART_ORDER_STATUSES.NOTIFY_ME;
        customButtonType = 'Ordinary';
        customIconName = 'red-envelop';
        buttonValue = addToCartNotifyButton;
        additionalButtonStyle = 'notifyMeButton';
    }
    if (outOfStock && notificationsEnabled && stockNotified) {
        buttonStatus = CART_ORDER_STATUSES.GET_NOTIFIED;

        buttonValue = addToCartNotifiedButton;
        customIconName = 'get-notified-icon';
    }

    if (!buttonValue) {
        buttonValue = '';
    }

    let iconClassName = 'addIcon';
    if (buttonStatus === CART_ORDER_STATUSES.NOTIFY_ME) { // customIconName === 'red-envelop'
        if (deviceType.isMobile) {
            iconClassName = 'notifyIconMobile';
        } else if (isFromProductCards) {
            iconClassName = 'notifyIcon';
        } else if (isFromProductCardList) {
            iconClassName = 'notifyIconList';
        }
    }

    return buttonStatus === CART_ORDER_STATUSES.HIDE ? null : (
        <Button
            automation-id={automationId}
            className={cx(
                buttonStatus === CART_ORDER_STATUSES.GET_NOTIFIED ? 'stockNotified' : '',
                'addtoCart',
                autoHeight ? 'autoHeightBtn' : '',
                customAddtoCartStyle || '',
                (customAddtoCartStyle && customAddtoCartStyle, isMultiCart && _isEmpty(productInfo)) && 'disabledBtn',
                additionalButtonStyle || ''
            )}
            type='button'
            size='Sm'
            buttonType={customButtonType}
            onClick={e => {
                e.preventDefault();
                //If the product is out of stock, clicking on the button will subscribe the user to the product
                if (buttonStatus === CART_ORDER_STATUSES.NOTIFY_ME) {
                    props.openStockNotificationModal();
                } else {
                    handleAddToCart();
                }
            }}
            isDisabled={(isMultiCart && _isEmpty(productInfo)) || maxValue === 0}
        >
            {buttonValue.toUpperCase()}

            <span className={cx('addtoCardIcon', themeAddCartIcon)}>
                <Icon
                    className={cx(iconClassName)}
                    width='17px'
                    height='16px'
                    viewBox='0 0 17 16'
                    key={Math.random()}
                    name={customIconName}
                />
            </span>
        </Button>
    );
};

const QuantityInput = props => {
    const { onChangeQty, quantity, skuId, localization, onIncrement, onDecrement, maxValue = MAX_PRODUCT_QUANTITY } = props;
    return (
        <QtyInput
            placeholder={localization[PLP_L10N.QTY_LABEL]}
            themeIncreBtn={cx('themeIncreBtn')}
            themeDescrBtn={cx('themeDescrBtn')}
            onChange={onChangeQty}
            minValue={0}
            maxValue={maxValue}
            value={quantity}
            id={skuId}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
        />
    );
};

export const AddToCart = props => {
    const bdaaSuggestion = useSelector(state => state.products.bdaaSuggestion);
    const isInitialMount = useRef(true);

    const { productInfo, isMultiCart, analyticsProductInfo, addToCartMessage, localization, isReadOnlyMode, suggestedQuantityEnabled } = props;

    const handleAddToCart = (quantity, isAdd, substitute = false, element = '') => {
        const { productInfo = [], baseUnit, ignoreSavedCart } = props;

        if (!_isEmpty(productInfo)) {
            const tempStoreVal = store.getState();
            const listType = analyticsProductInfo[0].list || (tempStoreVal.products.freeTextSearch
                ? DATA_LAYER_LISTS.SEARCH_LIST
                : tempStoreVal.products.queryResult === '&isFavorite=true'
                ? DATA_LAYER_LISTS.FAVORITE_LIST
                : DATA_LAYER_LISTS.PRODUCT_LIST);

            let origin_element = '';

            if ([DATA_LAYER_LISTS.SEARCH_LIST, DATA_LAYER_LISTS.FAVORITE_LIST, DATA_LAYER_LISTS.PRODUCT_LIST].includes(listType)) {
                origin_element = element === 'plusBtn' ? buttonClickEventConfigs.products.labels.productCardPlus : element === 'minusBtn' ? buttonClickEventConfigs.products.labels.productCardMinus : element === 'quantityInput' ? buttonClickEventConfigs.products.labels.productCardQuantityInput : element === 'addBtn' ? buttonClickEventConfigs.products.labels.productCardAddToCart : '';
            } else if (listType === DATA_LAYER_LISTS.HOMEPAGE_FAVORITE_LIST) {
                origin_element = element === 'plusBtn' ? buttonClickEventConfigs.home.labels.favoriteProductCardPlus : element === 'minusBtn' ? buttonClickEventConfigs.home.labels.favoriteProductCardMinus : element === 'quantityInput' ? buttonClickEventConfigs.home.labels.favoriteProductCardQuantityInput : element === 'addBtn' ? buttonClickEventConfigs.home.labels.favoriteProductCardAddToCart : '';
            }

            if (isMultiCart) {
                try {
                    const allProducts = tempStoreVal.products.products;

                    const gtmProductsGA4 = productInfo.map((p, index) => {
                        const { quantity, quantityType } = p;
                        const { name, sku, listPrice, brand, category } = allProducts.find((f) => f.code === p.code);

                        return {
                            item_id: sku,
                            item_name: name,
                            index,
                            item_brand: brand,
                            item_category: category,
                            item_list_id: listType,
                            item_list_name: listType,
                            item_variant: quantityType,
                            price: getProductPrice(listPrice),
                            quantity
                        };
                    });

                    addToCartEventGA4(_cloneDeep(gtmProductsGA4));
                } catch (ex) {
                    console.error(ex);
                }
            } else {
                const [product] = productInfo;

                if (quantity >= 0) {
                    const payload = {
                        ignoreSavedCart,
                        data: {
                            ...product,
                            quantity,
                            baseUnit,
                            addToCartMessage,
                            substitute,
                            isAdd,
                            freeItem: false,
                            promotionId: null,
                            analyticsProductInfo: {
                                ...analyticsProductInfo[0],
                                list: listType,
                                quantity,
                                origin_element,
                                oldQuantity: productInfo[0].quantity
                            },
                        }
                    };
                    if (props.isUpdateSap) {
                        props.actions.addToCartSapUpdate(payload);
                    } else {
                        props.actions.addToCart(payload);
                    }
                }

                try {
                    // Handles on minus click when quantity is 1 -> remove from cart
                    if(quantity === 0 || (!isAdd && product.quantity === 1)) {
                        removeFromCartEventGA4([{
                            item_id: analyticsProductInfo[0].id,
                            item_name: analyticsProductInfo[0].name,
                            index: 0,
                            item_brand: analyticsProductInfo[0].brand,
                            item_category: analyticsProductInfo[0].category,
                            item_list_id: listType,
                            item_list_name: listType,
                            item_variant: analyticsProductInfo[0].uom,
                            price: getProductPrice(analyticsProductInfo[0].price),
                            quantity: product.quantity || 1
                        }], origin_element);
                    }
                } catch (err) {
                    console.error(err)
                }
            }
        }
    };
    
    useEffect(() => {
        // Skip running on initial mount
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        const recommendedProduct = bdaaSuggestion.find(s => s.code === productInfo[0].code);

        if (recommendedProduct && suggestedQuantityEnabled && recommendedProduct?.quantity > 0) {
            handleAddToCart(recommendedProduct?.quantity, true, false, 'plusBtn');
        } else if (recommendedProduct && !suggestedQuantityEnabled && recommendedProduct?.quantity > 0) {
            handleAddToCart(recommendedProduct?.quantity, false, false, 'minusBtn');
        }
    }, [suggestedQuantityEnabled]);

    useEffect(() => {
        return () => {
            isInitialMount.current = true;
        }
    }, []);

    if (isReadOnlyMode && props.maxValue > 0) {
        return <div className={cx('maxQtyView')}>{props.maxValue}</div>
    }

    return productInfo[0].quantity > 0 ? (
        <QuantityInput
            {...props}
            onChangeQty={value => {
                const diff = value - productInfo[0].quantity;
                handleAddToCart(value, diff > 0, true, 'quantityInput');
            }}
            onIncrement={() => {
                handleAddToCart(1, true, false, 'plusBtn');
            }}
            onDecrement={() => {
                handleAddToCart(1, false, false, 'minusBtn');
            }}
            localization={localization}
            quantity={productInfo[0].quantity}
            maxValue={props.maxValue}
        />
    ) : (
        <AddToCartButton
            {...props}
            handleAddToCart={() => {
                handleAddToCart(1, true, false, 'addBtn');
            }}
        />
    );
};

AddToCart.propTypes = {
    automationId: PropTypes.string,
    buttonValue: PropTypes.string,
    customAddtoCartStyle: PropTypes.string,
    themeAddCartIcon: PropTypes.string,
    isMultiCart: PropTypes.bool,
    iconName: PropTypes.string,
    isUpdateSap: PropTypes.bool,
    addToCartMessage: PropTypes.string,
    stockNotified: PropTypes.bool,
    isReadOnlyMode: PropTypes.bool,
    ignoreSavedCart: PropTypes.bool, //Marketing materials and return empties should not update saved shopping cart
    suggestedQuantityEnabled: PropTypes.bool
};

AddToCart.defaultProps = {
    automationId: '',
    buttonValue: '',
    customAddtoCartStyle: '',
    themeAddCartIcon: '',
    isMultiCart: false,
    ignoreSavedCart: false,
    iconName: '',
    isUpdateSap: false,
    isReadOnlyMode: false,
    addToCartMessage: '',
    suggestedQuantityEnabled: false,
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            addToCart,
            addToCartSapUpdate
        },
        dispatch
    )
});

const mapStateToProps = state => {
    return {
        localization: _get(state, 'pageContent.localization'),
        deviceType: _get(state, 'context.deviceType'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
