/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useSelector } from 'react-redux';
import { MY_ACCOUNT_L10N, URL_PATHS } from '../../../common/Constants';
import classNames from 'classnames/bind';
import Icon from '../../../components/core/Icon/Icon';
import Link from 'react-router/lib/Link';

import * as styles from './MyAccount.css';
import { buttonClickEventConfigs, buttonClickEvent } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

const AccountComponents = {
    'Order-Information-MyAccount-React': {
        svg: 'order-history'
    },
    'Shopping-List-MyAccount-React': {
        svg: 'add-cart'
    },
    'My-Rewards-MyAccount-React': {
        svg: 'trofy'
    },
    'My-Equipment-MyAccount-React': {
        svg: 'cooler'
    },
    'My-Services-MyAccount-React': {
        svg: 'tickets'
    },
    'Account-Settings-MyAccount-React': {
        svg: 'settings'
    },
    'My-Reports-MyAccount-React': {
        svg: 'reports'
    }
};
export const AccountCard = ({ name, childComponents, uid }) => {
    const localization = useSelector(state => state.pageContent.localization);
    const loyaltyEnabled = useSelector(state => state.loginPage.loyaltyEnabled);
    
    return (
        <div
            className={cx(
                'gridChild',
                name === localization[MY_ACCOUNT_L10N.MY_REWARDS] && !loyaltyEnabled && 'hidden-card'
            )}
        >
            <Icon width='32px' height='32px' viewBox='0 0 32 32' name={AccountComponents[uid]?.svg || ''} />
            <h3 className={cx('name')}>{name}</h3>
            {childComponents && (
                <ul>
                    {childComponents.map(cComponent => {
                        return (
                            <li className={cx('action')} key={cComponent.name}>
                                <span className={cx('arrow')}></span>
                                <Link to={cComponent?.url} onClick={() => {
                                    try {
                                        const gaLinks = {
                                            [URL_PATHS.ACCOUNT_SHOPPING_LIST + '/quickOrder']: {
                                                action: buttonClickEventConfigs.myAccount.actions.shoppingList,
                                                label: buttonClickEventConfigs.myAccount.labels.shoppingListQuickOrder
                                            },
                                            [URL_PATHS.ACCOUNT_SETTINGS + '/updatePassword']: {
                                                action: buttonClickEventConfigs.myAccount.actions.accountSettings,
                                                label: buttonClickEventConfigs.myAccount.labels.accountSettingsEditPassword
                                            }
                                        };
                                        const foundLink = gaLinks[cComponent?.url];
                                        if (foundLink) {
                                            buttonClickEvent(foundLink.action, foundLink.label, buttonClickEventConfigs.myAccount.categories.myAccount);
                                        }
                                    } catch (ex) {
                                        console.error(ex);
                                    }
                                }}>{cComponent?.name}</Link>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};
