import React from 'react';
import classNames from 'classnames/bind';
import Image from '../../../components/core/Image/Image';

import * as styles from './PerformanceTips.css';

const cx = classNames.bind(styles);

const PerformanceTips = ({ selectRandomTip, changeTipBackground, backgroundColor, currentPerformanceTip, coolerPerformanceTips, coolerTipNext, coolerTipTitle }) => {

    if(!coolerPerformanceTips.length) {
        return null;
    }

    const tip = coolerPerformanceTips[currentPerformanceTip];
    const changeTip = () => {
        changeTipBackground(1);
        selectRandomTip();
    }

    return (
        tip && <section className={cx('Section')} style={{ backgroundColor }} >
            <div className={cx('performace-tips-left')}>
                <h5>{coolerTipTitle}</h5>
                <h3>{tip.title}</h3>
                <p>{tip.text}</p>
                <button type='button' onClick={changeTip}>{coolerTipNext}</button>
            </div>
            <div className={cx('img-wrapper')}>
                <Image
                    src={tip.icon.url}
                    alt={tip.icon.altText}
                />
            </div>
        </section>
    )
}

export default PerformanceTips;