import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import Button from '../../../components/core/Button/Button';
import Icon from '../../../components/core/Icon/Icon';
import Image from '../../../components/core/Image/Image';
import NavLink from '../../../components/core/NavLink/NavLink';
import * as styles from './OrderConfirm.css';
import Layout from '../../../components/site/Layout/Layout';
import { getPageContent } from '../../../actions/PageContentAction';
import { getAllLoyaltyNotifications, displayNotificationBanner } from '../../../actions/LoyaltyAction';
import {
    PAGE_ID,
    ORDER_CONFIRM_L10N,
    URL_PATHS,
    GENERIC_L10N,
    CART_PRICING_TYPES,
    DATA_LAYER_LISTS
} from '../../../common/Constants';
import { getCheckoutData } from '../../../actions/CheckoutAction';
import { getOrderConfirmationData, updateSurveyClickedDate } from '../../../actions/OrderConfirmAction';
import {
    formatHungaryTousends,
    getImageByFormat,
    loadScriptFunc,
    redirectTo,
    trimString,
    removeDoubleQuotes
} from '../../../common/Utils/Utils';
import { getCountry, getUserInfo } from '../../../common/UserManager/UserManager';
import { productClickOrderConfirmDataLayerFilterGA4 } from '../../../service/DataLayerFilter';
import { productClickEventGA4 } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

export class OrderConfirm extends Component {
    static propTypes = {
        localization: PropTypes.objectOf(PropTypes.string),
        carts: PropTypes.arrayOf(PropTypes.any),
        orderTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        deviceType: PropTypes.objectOf(PropTypes.bool),
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static defaultProps = {
        localization: {},
        carts: [],
        orderTotal: '',
        deviceType: {},
        commonLocalization: {}
    };

    componentPrintRef = null;

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { params } = this.props;
        const { orderId } = params || {};
        this.props.actions.getPageContent({ pageId: PAGE_ID.ORDER_CONFIRMATION, orderId });
        this.props.actions.displayNotificationBanner(false);
        this.props.actions.getAllLoyaltyNotifications();
    }

    async componentDidUpdate(prevProps) {
        const {
            outlet,
            accountNumber,
            customerGaugeLanguage,
            surveyId,
            countryIsoCode,
            showCustomerGaugePopUp,
            customerChannel
        } = this.props;
        const userInfo = getUserInfo();
        const outletWithoutQuotes = removeDoubleQuotes(outlet);
        if (
            (prevProps.showCustomerGaugePopUp !== showCustomerGaugePopUp || prevProps.surveyId !== surveyId) &&
            showCustomerGaugePopUp &&
            outletWithoutQuotes &&
            accountNumber &&
            customerGaugeLanguage &&
            surveyId
        ) {
            await loadScriptFunc(
                'https://survey.eu.customergauge.com/pop-up/pop-up.iife.js',
                `CG.init({
                "surveyId": "${surveyId}",
                "language": "${customerGaugeLanguage}",
                "params": {
                "Account Name": "${accountNumber}-${outletWithoutQuotes}",
                "Account Number": "${accountNumber}",
                "Outlet / Customer Name": "${outletWithoutQuotes}",
                "Email": "${userInfo.email}",
                "First Name": "${userInfo.firstName}",
                "Last Name": "${userInfo.lastName}",
                "Country": "${countryIsoCode}",
                "Customer Channel": "${customerChannel}",
                "Touchpoint": "Customer Portal Overview",
                }
                });
                `
            );
        }
    }

    reactToPrintContent = () => {
        return this.componentPrintRef;
    };

    setPrintComponentRef = ref => {
        this.componentPrintRef = ref;
    };

    formatCartname = (name, count = 0) => {
        const cartName = `${name} (${count})`;
        return name ? cartName : '';
    };

    onProductClick = item => {
        try {
            const data = _cloneDeep(item);
            productClickEventGA4(productClickOrderConfirmDataLayerFilterGA4(data), DATA_LAYER_LISTS.ORDER_CONFIRM_LIST);
        } catch (ex) {
            console.error(ex);
        }
    };

    renderShippingAddress = (deliveryAddress, index) => {
        const { localization } = this.props;
        const { firstName = '', lastName = '', line1 = '', line2 = '', town = '', postalCode = '' } =
            deliveryAddress || {};
        return (
            <div automation-id={`at-ship-to${index}`} className={cx('col4', 'sm12', 'mbrder')}>
                <h5 className={cx('tableTitle', 'sm5')}>{localization[ORDER_CONFIRM_L10N.SHIP_TO]}</h5>
                <span className={cx('cartval', 'sm7')}>
                    <p>{`${firstName} ${lastName} /`}</p>
                    <p>{`${line1} ${line2} / ${postalCode} ${town}`}</p>
                </span>
            </div>
        );
    };

    renderBillingAddress = (billingAddress, index) => {
        const { localization } = this.props;
        const { firstName = '', companyName = '', line1 = '', line2 = '', town = '', postalCode = '' } =
            billingAddress || {};
        return (
            <div automation-id={`at-billing-address${index}`} className={cx('col4', 'sm12', 'mbrder')}>
                <h5 className={cx('tableTitle', 'sm5')}>{localization[ORDER_CONFIRM_L10N.BILLING_ADDRESS]}</h5>
                <span className={cx('cartval', 'sm7')}>
                    <p>{`${firstName} ${companyName} /`}</p>
                    <p>{`${line1} ${line2} / ${postalCode} ${town}`}</p>
                </span>
            </div>
        );
    };

    renderCartInfoItems = (label, value, automationId, className, url) => {
        const style = className ? cx(className.split(' ')) : cx('col4', 'sm12', 'mbrder');
        if (url) {
            return (
                <div automation-id={automationId} className={cx(style)}>
                    <h5 className={cx('tableTitle', 'sm5')}>{label}</h5>
                    <span className={cx('cartval', 'sm7')}>{value}</span>
                </div>
            );
        }
        return (
            <div automation-id={automationId} className={cx(style)}>
                <h5 className={cx('tableTitle', 'sm5')}>{label}</h5>
                <span className={cx('cartval', 'sm7')}>{value}</span>
            </div>
        );
    };

    renderCartInfo = (key, orderData, index) => {
        const {
            billingAddress = {},
            deliveryAddress = {},
            code,
            user = {},
            statusDisplay,
            formattedDeliveryDate,
            formattedCreatedDate,
            paymentTerms,
            pallets,
            weight
        } = orderData;
        const { localization, commonLocalization, hidePaymentTermsForIndirectCustomers } = this.props;
        return (
            <div className={cx('confirmcartinfo', 'clearfix')}>
                {this.renderCartInfoItems(
                    localization[ORDER_CONFIRM_L10N.ORDER_NUMBER],
                    code,
                    `at-order-number${index}`,
                    'col3 sm12 cartinfoleft mbrder textBrand',
                    `${URL_PATHS.ORDER_DETAILS}/${code}`
                )}
                <div className={cx('col9', 'sm12', 'cartinforight')}>
                    <div className={cx('confirmcartinforows', 'clearfix')}>
                        {this.renderCartInfoItems(
                            localization[ORDER_CONFIRM_L10N.PLACED_BY],
                            user.name,
                            `at-placed-by${index}`
                        )}
                        {this.renderShippingAddress(deliveryAddress, index)}
                        {this.renderBillingAddress(billingAddress, index)}
                    </div>
                    <div className={cx('confirmcartinforows', 'clearfix')}>
                        {this.renderCartInfoItems(
                            localization[ORDER_CONFIRM_L10N.ORDER_STATUS],
                            statusDisplay,
                            `at-order-status${index}`
                        )}
                        {this.renderCartInfoItems(
                            localization[ORDER_CONFIRM_L10N.DATE_PLACED],
                            formattedCreatedDate,
                            `at-date-placed${index}`
                        )}
                        {this.renderCartInfoItems(
                            localization[ORDER_CONFIRM_L10N.DELIVERY_DATE],
                            formattedDeliveryDate,
                            `at-delivery-date${index}`
                        )}
                    </div>
                    <div className={cx('confirmcartinforows', 'clearfix', 'cartlastrow')}>
                        {!hidePaymentTermsForIndirectCustomers &&
                            this.renderCartInfoItems(
                                localization[ORDER_CONFIRM_L10N.PAYMENT_TERMS],
                                paymentTerms,
                                `at-payment-terms${index}`
                            )}
                        {this.renderCartInfoItems(
                            `${localization[ORDER_CONFIRM_L10N.PALLET]}`,
                            pallets,
                            `at-no-of-pallets-required${index}`
                        )}
                        {this.renderCartInfoItems(
                            localization[ORDER_CONFIRM_L10N.WEIGHT],
                            `${weight} ${commonLocalization[GENERIC_L10N.WEIGHT_SYMBOL]}`,
                            `at-total-weight${index}`,
                            'col4 sm12 mlastbrder'
                        )}
                    </div>
                </div>
            </div>
        );
    };

    renderConfirmationProducts = (entries, isPricingAvailable) => {
        const { localization, commonLocalization } = this.props;
        return (
            <div className={cx('detailsleft', 'col8', 'md12', 'sm12')}>
                <ul className={cx('detailsleftList')}>
                    <li className={cx('detailstheader', 'hiddenSm')}>
                        <div className={cx('col2', 'md2')} />
                        <div className={cx('col5', 'md5')}>{localization[ORDER_CONFIRM_L10N.ITEM]}</div>
                        <div className={cx('col3', 'md3')}>{localization[ORDER_CONFIRM_L10N.QUANTITY]}</div>
                        {isPricingAvailable ? (
                            <div className={cx('col2', 'md2')}>{localization[ORDER_CONFIRM_L10N.PRICE]}</div>
                        ) : null}
                    </li>
                    {entries &&
                        entries.map((item, i) => {
                            const { product, totalPrice, quantity, freeItem } = item;
                            const { images, name, codeShort, code } = product || {};
                            const image = getImageByFormat(images);
                            const productUrl = `${URL_PATHS.PDP}/${code}`;

                            return (
                                <li key={`products-${i.toString()}`} className={cx('detailslistItem')}>
                                    <div
                                        automation-id='at-product-image'
                                        className={cx('col2', 'md2', 'sm12', 'itemImg')}
                                    >
                                        <div
                                            role='presentation'
                                            onClick={() => this.onProductClick(item)}
                                            className={cx('prodImage')}
                                        >
                                            <Image
                                                src={image.url}
                                                linkUrl={productUrl}
                                                alt={image.altText}
                                                automationId='at-order-item-image'
                                                isInternal={image.defaultImage}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        role='presentation'
                                        onClick={() => this.onProductClick(item)}
                                        automation-id='at-product-name'
                                        className={cx('col5', 'md5', 'sm12', 'prodname')}
                                    >
                                        <h4>
                                            <NavLink linkUrl={productUrl} theme={cx('prodLink')}>
                                                {freeItem && (
                                                    <span className={cx('freeTxt', 'textBrand')}>
                                                        {commonLocalization[GENERIC_L10N.FREE]}
                                                    </span>
                                                )}{' '}
                                                {name}
                                            </NavLink>
                                        </h4>
                                        <h5 className={cx('hiddenLg', 'hiddenMd', 'hiddenXl')}>
                                            {localization[ORDER_CONFIRM_L10N.PRODUCT_DETAILS]}
                                        </h5>
                                        <p>
                                            {localization[GENERIC_L10N.SKU]}: {codeShort}
                                        </p>
                                    </div>
                                    <div
                                        automation-id='at-product-quantity'
                                        className={cx('col3', 'md3', 'sm12', 'qtyTxt')}
                                    >
                                        <div className={cx('listItemhead', 'hiddenLg', 'hiddenMd', 'hiddenXl', 'sm6')}>
                                            {localization[ORDER_CONFIRM_L10N.QUANTITY]}
                                        </div>
                                        <span className={cx('cartval', 'sm6')}>{quantity}</span>
                                    </div>
                                    {isPricingAvailable ? (
                                        <div
                                            automation-id='at-product-price'
                                            className={cx('col2', 'md2', 'sm12', 'priceTxt')}
                                        >
                                            <div
                                                className={cx(
                                                    'listItemhead',
                                                    'hiddenLg',
                                                    'hiddenMd',
                                                    'hiddenXl',
                                                    'sm6'
                                                )}
                                            >
                                                {localization[ORDER_CONFIRM_L10N.PRICE]}
                                            </div>
                                            <span className={cx('cartval', 'sm6')}>
                                                {totalPrice && `${formatHungaryTousends(totalPrice.formattedValue)}`}
                                            </span>
                                        </div>
                                    ) : null}
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    };

    renderPricebreakdown = (cchPricingData, orderData) => {
        const { localization, commonLocalization, carts, deviceType } = this.props;
        const { subTotal, totalPrice, totalItems, totalPriceWithTax, totalTax } = orderData;
        const { pricingInfoStyle, pricingConditions, yourSavings, orderEmpties, depositFee } = cchPricingData;
        const priceBreakDownView = carts.length === 1 && deviceType.isMobile;
        const breakdownOpen = () => {
            const modalId = document.getElementById('pricebreakdown');
            if (modalId && priceBreakDownView) {
                modalId.classList.toggle('OrderConfirm-pricebreakdownOpen');
            }
        };
        return (
            <div className={cx('detailsright', 'col4', 'md12', 'sm12', { pricebreakdownview: priceBreakDownView })}>
                <div
                    className={cx('pricebreakdown', { 'OrderConfirm-pricebreakdownOpen': !priceBreakDownView })}
                    id='pricebreakdown'
                >
                    <h4>
                        <span className={cx('hiddenSm')}>
                            {' '}
                            <Icon
                                className={cx('pricemobileIcon')}
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                                name='financial-info'
                            />{' '}
                            {localization[ORDER_CONFIRM_L10N.PRICE_BREAKDOWN]}
                        </span>
                        <button
                            type='button'
                            aria-label='Price Breakdown'
                            onClick={breakdownOpen}
                            className={cx('pricebreakdownup', 'hiddenLg', 'hiddenMd', 'hiddenXl')}
                        >
                            {' '}
                            <Icon
                                className={cx('pricemobileIcon')}
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                                name='financial-info'
                            />{' '}
                            {localization[ORDER_CONFIRM_L10N.PRICE_BREAKDOWN]}
                            {priceBreakDownView && (
                                <span className={cx('rightArrow')}>
                                    <Icon iconType='svg' width='24px' height='24px' viewBox='0 0 24 24' name='up' />
                                </span>
                            )}
                        </button>
                    </h4>
                    <div className={cx('pricebreakdowninner')}>
                        <ul className={cx('pricebreakdownList')}>
                            <li automation-id='at-product' className={cx('clearfix')}>
                                <span className={cx('totVal')}>{localization[ORDER_CONFIRM_L10N.PRODUCTS]}</span>
                                <span className={cx('totPrice')}>{totalItems && `${totalItems}`}</span>
                            </li>
                            <li automation-id='at-total-before-taxes' className={cx('clearfix')}>
                                <span className={cx('totVal')}>
                                    {localization[ORDER_CONFIRM_L10N.TOTAL_BEFORE_TAXES]}
                                </span>
                                <span className={cx('totPrice')}>
                                    {subTotal && `${formatHungaryTousends(subTotal.formattedValue)}`}
                                </span>
                            </li>
                            {totalTax && (
                                <li>
                                    <span className={cx('totVal')}>{commonLocalization[GENERIC_L10N.TOTAL_TAX]} </span>
                                    <span className={cx('totPrice')}>
                                        {formatHungaryTousends(totalTax.formattedValue)}
                                    </span>
                                </li>
                            )}
                            {depositFee && (
                                <li>
                                    <span className={cx('totVal')}>
                                        {commonLocalization[GENERIC_L10N.DEPOSIT_FEE]}{' '}
                                    </span>
                                    <span className={cx('totPrice')}>
                                        {formatHungaryTousends(depositFee.formattedValue)}
                                    </span>
                                </li>
                            )}
                            {pricingInfoStyle === CART_PRICING_TYPES.SAVINGS && !_isEmpty(yourSavings) && (
                                <li key='yourSavings' className={cx('clearfix')}>
                                    <span className={cx('totVal')}>
                                        {commonLocalization[GENERIC_L10N.YOUR_SAVINGS]}{' '}
                                    </span>
                                    <span className={cx('totPrice')}>
                                        {formatHungaryTousends(yourSavings.formattedValue)}
                                    </span>
                                </li>
                            )}
                            {orderEmpties && (
                                <li automation-id='at-order-empties' className={cx('clearfix')}>
                                    <span className={cx('totVal')}>
                                        {localization[ORDER_CONFIRM_L10N.ORDER_EMPTIES]}
                                    </span>
                                    <span className={cx('totPrice')}>
                                        {formatHungaryTousends(orderEmpties.formattedValue)}
                                    </span>
                                </li>
                            )}
                            <li automation-id='at-order-total' className={cx('clearfix', 'breakdowntotal')}>
                                <span className={cx('totVal')}>{localization[ORDER_CONFIRM_L10N.ORDER_TOTAL]}</span>
                                <span className={cx('totPriceTt')}>
                                    {totalPriceWithTax && `${formatHungaryTousends(totalPriceWithTax.formattedValue)}`}
                                </span>
                            </li>
                            {pricingInfoStyle === CART_PRICING_TYPES.PRICING &&
                                (pricingConditions || []).map((item, i) => {
                                    return (
                                        <li key={`pricing-${i.toString()}`} className={cx('clearfix')}>
                                            <span className={cx('totVal')}>{trimString(item.key)}</span>
                                            <span className={cx('totPrice')}>
                                                {formatHungaryTousends(item.value.formattedValue)}
                                            </span>
                                        </li>
                                    );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { carts, localization, orderTotal, microsoftFormsLink } = this.props;
        const country = getCountry();

        return (
            <Layout showDashboardHeader showOutlet>
                <div ref={this.setPrintComponentRef} className={cx('orderconfirmationpage', 'clearfix')}>
                    <h2>
                        <span automation-id='at-order-confirmation-title' className={cx('pageTitle')}>
                            {localization[ORDER_CONFIRM_L10N.TITLE]}{' '}
                        </span>
                        <div className={cx('orderPrint', 'hiddenMd', 'hiddenSm', 'pull-right')}>
                            {country !== 'Belarus' && (
                                <ReactToPrint
                                    content={this.reactToPrintContent}
                                    documentTitle='document'
                                    removeAfterPrint
                                    trigger={() => (
                                        <Button
                                            type='button'
                                            aria-label='print'
                                            automation-id='at-print-option'
                                            onClick={window.print}
                                        >
                                            <Icon
                                                iconType='svg'
                                                width='25px'
                                                height='24px'
                                                viewBox='0 0 25 24'
                                                name='print'
                                            />
                                        </Button>
                                    )}
                                />
                            )}
                        </div>
                    </h2>
                    <div
                        automation-id='at-order-confirmation-text'
                        className={cx('confirmthanksinfo', 'col6', 'md7', 'sm12')}
                    >
                        <p>{localization[ORDER_CONFIRM_L10N.TITLE_TEXT]}</p>
                    </div>

                    {!this.props.surveyId && microsoftFormsLink ? (
                        <div className={cx('microsoftLink')}>
                            <span>
                                <NavLink
                                    linkUrl={microsoftFormsLink}
                                    onClick={this.props.actions.updateSurveyClickedDate}
                                >
                                    {localization[ORDER_CONFIRM_L10N.MICROSOFTLINK_TEXT]}
                                </NavLink>
                            </span>
                        </div>
                    ) : (
                        <p />
                    )}

                    {orderTotal && (
                        <div automation-id='at-order-total' className={cx('confirmtotalcounts', 'col6', 'md5', 'sm12')}>
                            {localization[ORDER_CONFIRM_L10N.ORDER_TOTAL]} <span>{orderTotal}</span>
                        </div>
                    )}
                    {/*Cart 1 HTML Start*/}
                    {carts &&
                        carts.map((item, i) => {
                            const { cartTranslatedName, cartCount, orderData = {}, cchPricingData = {} } = item;
                            const { entries, subTotal } = orderData || {};
                            const key = `cart${i}`;
                            const index = i + 1;
                            return (
                                <div key={key}>
                                    <h3 automation-id={`at-cart-number${index}`} className={cx('cartsubtitle')}>
                                        {this.formatCartname(cartTranslatedName, cartCount)}
                                    </h3>
                                    {this.renderCartInfo(key, orderData, index)}
                                    <h4 translate='no' className={cx('producttitle')}>
                                        {localization[ORDER_CONFIRM_L10N.PRODUCTS]}
                                    </h4>
                                    {this.renderConfirmationProducts(entries, !_isEmpty(subTotal))}
                                    {!_isEmpty(subTotal) ? this.renderPricebreakdown(cchPricingData, orderData) : null}
                                </div>
                            );
                        })}
                    {/*Cart 1 HTML End*/}
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = state => ({
    localization: _get(state, 'pageContent.localization'),
    orderTotal: _get(state, 'checkoutData.total'),
    carts: _get(state, 'confirmationData.orderConfirmationList') || [],
    microsoftFormsLink: _get(state, 'confirmationData.microsoftFormsLink'),
    deviceType: _get(state, 'context.deviceType'),
    commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`),
    hidePaymentTermsForIndirectCustomers: _get(state, 'confirmationData.hidePaymentTermsForIndirectCustomers'),
    outlet: _get(state, 'pageContent.chatCurrentCompanyName'),
    accountNumber: _get(state, 'pageContent.chatCurrentOutletId'),
    customerGaugeLanguage: _get(state, 'confirmationData.customerGaugeLanguage'),
    surveyId: _get(state, 'confirmationData.surveyId'),
    countryIsoCode: _get(state, 'confirmationData.countryIsoCode'),
    customerChannel: _get(state, 'confirmationData.customerChannel'),
    showCustomerGaugePopUp: _get(state, 'confirmationData.showCustomerGaugePopUp')
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPageContent,
            getCheckoutData,
            getOrderConfirmationData,
            getAllLoyaltyNotifications,
            displayNotificationBanner,
            updateSurveyClickedDate
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirm);
