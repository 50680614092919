import TagManager from 'react-gtm-module';
import _get from 'lodash/get';
import { getCustomerId, getUserId, getCurrency, getLocalization } from '../UserManager/UserManager';
import { LOGIN_INFO } from '../Constants';
import { assignProductListCurrency, assignProductListIDsGA4 } from '../../service/DataLayerFilter';
import { SessionStorage } from '../Storage/Storage';
import ReactGA4 from '../GoogleAnalytics/GA4';

export const getGTMuserId = () => getCustomerId() || getUserId();

const gaMutableConfig = {
    localization: null,
    sapOutboundId: null,
    campaignId: null
};

const updateMutableLocalization = () => {
    gaMutableConfig.localization = getLocalization().siteId;
};

const updateMutableSapOutboundId = () => {
    const [newOutboundId, newCampaignId] = ['sap-outbound-id', 'utm_campaign'].map(
        p => window.location.href.match(new RegExp(`${p}=(\\w+)`))?.[1] || null
    );
    if (newOutboundId) {
        [gaMutableConfig.sapOutboundId, gaMutableConfig.campaignId] = [newOutboundId, newCampaignId];
        SessionStorage.setData('sapOutboundId', newOutboundId);
        SessionStorage.setData('campaignId', newCampaignId);
        return;
    }

    const [foundInStorageOutboundId, foundInStorageCampaignId] = ['sapOutboundId', 'campaignId'].map(p =>
        SessionStorage.getData(p)
    );
    if (foundInStorageOutboundId) {
        [gaMutableConfig.sapOutboundId, gaMutableConfig.campaignId] = [
            foundInStorageOutboundId,
            foundInStorageCampaignId
        ];
    }
};

export const getGaMutableConfig = () => {
    updateMutableLocalization();
    updateMutableSapOutboundId();
    return gaMutableConfig;
};

const getSapMarketingData = () => {
    const mutableGaConfig = getGaMutableConfig();
    const sapMarketingCountries = ['poland', 'russia', 'czech', 'hungary', 'ng'];
    return sapMarketingCountries.includes(mutableGaConfig.localization)
        ? { sapOutboundId: mutableGaConfig.sapOutboundId, campaignId: mutableGaConfig.campaignId }
        : {};
};

let ga4enabled = false;
const dataLayerVariables = {};
let gaConfig = {};
window.dataLayer = [];
export const initializeGTAG = state => {
    const siteId = getLocalization()[LOGIN_INFO.SITE_ID] || '';

    gaConfig = _get(state, 'context.environment.gaConfig', {});

    if (gaConfig[siteId] && gaConfig[siteId].gtgId) {
        const tagManagerArgs = {
            gtmId: gaConfig[siteId].gtgId,
            dataLayer: {
                userId: getGTMuserId(),
                marketingArea: gaConfig[siteId].marketingArea || ''
            }
        };

        TagManager.initialize(tagManagerArgs);

        ga4enabled = !!gaConfig[siteId]?.ga4Id;
        dataLayerVariables.userId = getGTMuserId();
        dataLayerVariables.marketingArea = gaConfig[siteId].marketingArea || '';
    }
};

const customDimensionsGA4 = [
    'search_term',
    'product_code',
    'marketingArea',
    'userId',
    'sapOutboundId',
    'campaignId',
    'event_category',
    'event_action',
    'event_label',
    'origin_element'
];
const customDimensions = [...['ecommerce'], ...customDimensionsGA4];
const customDimensionsClearObj = customDimensions.reduce((obj, prop) => Object.assign(obj, { [prop]: undefined }), {});

const pushDataLayerEventData = (mainEventData = {}) => {
    const eventData = { ...dataLayerVariables, ...getSapMarketingData(), ...mainEventData };
    window.dataLayer.push({ ...customDimensionsClearObj });
    window.dataLayer.push(eventData);
};

export const viewItemListEventGA4 = (productImpressionsListData, searchTerm) => {
    if (!ga4enabled) return;

    const impressions = assignProductListCurrency(assignProductListIDsGA4(productImpressionsListData));

    const viewItemListEventData = {
        event: 'view_item_list',
        search_term: searchTerm || '',
        ecommerce: {
            items: impressions
        }
    };

    pushDataLayerEventData(viewItemListEventData);
};

export const viewItemEventGA4 = productDetailData => {
    if (!ga4enabled) return;

    const products = assignProductListCurrency(assignProductListIDsGA4(productDetailData));

    const viewItemEventData = {
        event: 'view_item',
        ecommerce: {
            items: products
        }
    };

    pushDataLayerEventData(viewItemEventData);
};

export const addToCartEventGA4 = (productDetailData, originElement) => {
    if (!ga4enabled) return;

    if (!productDetailData || (productDetailData?.length === 1 && productDetailData[0].quantity === 0)) {
        return;
    }

    const products = assignProductListCurrency(assignProductListIDsGA4(productDetailData));

    const addItemEventData = {
        event: 'add_to_cart',
        origin_element: originElement,
        ecommerce: {
            items: products
        }
    };

    pushDataLayerEventData(addItemEventData);
};

export const removeFromCartEventGA4 = (productDetailData, originElement) => {
    if (!ga4enabled) return;

    if (!productDetailData?.length) return;

    const products = assignProductListCurrency(assignProductListIDsGA4(productDetailData));

    const removeItemEventData = {
        event: 'remove_from_cart',
        origin_element: originElement,
        ecommerce: {
            items: products
        }
    };

    pushDataLayerEventData(removeItemEventData);
};

export const productClickEventGA4 = (productDetailData, listName) => {
    if (!ga4enabled) return;

    const products = assignProductListCurrency(assignProductListIDsGA4(productDetailData));

    products.forEach(p => {
        p.item_list_id = listName || '';
        p.item_list_name = listName || '';
        p.quantity = p.quantity || 1;
    });

    const productClickEvenData = {
        event: 'select_item',
        ecommerce: {
            items: products
        }
    };

    pushDataLayerEventData(productClickEvenData);
};

export const addToFavoritesEventGA4 = productCode => {
    if (!ga4enabled) return;

    const addToFavoritesEventData = {
        event: 'add_to_favorites',
        product_code: productCode.substring(22)
    };

    pushDataLayerEventData(addToFavoritesEventData);
};

export const removeFromFavoritesEventGA4 = productCode => {
    if (!ga4enabled) return;

    const removeFromFavoritesEventData = {
        event: 'remove_from_favorites',
        product_code: productCode.substring(22)
    };

    pushDataLayerEventData(removeFromFavoritesEventData);
};

export const checkoutEventGA4 = cartProducts => {
    if (!ga4enabled) return;

    const products = assignProductListCurrency(assignProductListIDsGA4(cartProducts));

    const checkoutData = {
        event: 'begin_checkout',
        ecommerce: {
            items: products
        }
    };

    pushDataLayerEventData(checkoutData);
};

export const purchaseEvent = (cartProductsGA4, transactionId, affiliation, revenue, tax, shipping, coupon) => {
    if (!ga4enabled) {
        return;
    }

    const items = assignProductListCurrency(assignProductListIDsGA4(cartProductsGA4));

    const purchaseData = {
        event: 'purchase',
        ecommerce: {
            transaction_id: transactionId,
            affiliation,
            value: revenue,
            tax,
            shipping,
            currency: getCurrency().isocode,
            coupon,
            items
        }
    };

    pushDataLayerEventData(purchaseData);
};

export const promotionImpressionEventGA4 = promotions => {
    if (!ga4enabled) return;

    const promotionImpressionEventData = {
        event: 'view_promotion',
        ecommerce: {
            items: promotions
        }
    };

    pushDataLayerEventData(promotionImpressionEventData);
};

export const promotionClickEventGA4 = promotions => {
    if (!ga4enabled) return;

    const promotionClickEventData = {
        event: 'select_promotion',
        ecommerce: {
            items: promotions
        }
    };

    pushDataLayerEventData(promotionClickEventData);
};

export const userLoggedInEvent = () => {
    if (!ga4enabled) {
        return;
    }

    const USER_ID = getGTMuserId();
    if (!USER_ID) {
        delete dataLayerVariables.userId;
        return;
    }

    const userLoggedInEventData = {
        event: 'login',
        userId: USER_ID
    };

    ReactGA4.set({ userId: USER_ID });
    dataLayerVariables.user_id = USER_ID; // ga4 session
    dataLayerVariables.userId = USER_ID; // data layer
    pushDataLayerEventData(userLoggedInEventData);
};

export const searchEvent = (search_term = '') => {
    if (!ga4enabled) {
        return;
    }

    if (!search_term) return;

    const searchEventData = {
        event: 'search',
        search_term,
        ecommerce: {
            search_term
        }
    };

    pushDataLayerEventData(searchEventData);
};

export const buttonClickEventConfigs = {
    login: {
        categories: {
            login: 'Login'
        },
        actions: {
            loginForm: 'Login - Login Form'
        },
        labels: {
            loginFormLoginButton: 'Login - Login Form - Login Button'
        }
    },
    header: {
        categories: {
            header: 'Header'
        },
        actions: {
            cartView: 'Header - Cart View',
            myProfile: 'Header - My Profile'
        },
        labels: {
            cartViewMinicart: 'Header - Cart View - Minicart',
            myProfileMyAccount: 'Header - My Profile - My Account',
            myProfileLogout: 'Header - My Profile - Logout'
        }
    },
    myAccount: {
        categories: {
            myAccount: 'My Account'
        },
        actions: {
            shoppingList: 'My Account - Shopping List',
            accountSettings: 'My Account - Account Settings'
        },
        labels: {
            shoppingListQuickOrder: 'My Account - Shopping List - Quick Order',
            accountSettingsEditPassword: 'My Account - Account Settings - Edit Password'
        }
    },
    fsa: {
        categories: {
            tipsNTricks: 'Tips&Tricks'
        },
        actions: {
            getStarted: 'FSA – Get Started',
            learnMore: 'FSA – Learn More',
            downloadImages: 'FSA – Download Images',
            optionalGuidance: 'FSA – Operational Guidance',
            readyToStart: 'FSA – Ready to Start'
        },
        labels: {
            getStartedPrefix: 'FSA – Get Started – ',
            getStartedOpenPopUp: 'FSA – Get Started – Open Pop-Up',
            getStartedClosePopUp: 'FSA – Get Started – Close Pop-Up',
            learnMoreOpenPopup: 'FSA – Learn More – Open Pop-Up',
            learnMoreClosePopup: 'FSA – Learn More – Close Pop-Up',
            learnMoreAnchorTopTips: 'FSA – Learn More – Anchor Top Tips',
            downloadImages: 'FSA – Download Images',
            operationalGuidancePrefix: 'FSA – Operational Guidance – ',
            readyToStartGetStarted: 'FSA – Ready to Start – Get Started',
            readyToStartCheckOurTips: 'FSA – Ready to Start – Check Our Tips'
        }
    },
    products: {
        categories: {
            products: 'Products'
        },
        actions: {
            sort: 'Products - Sort',
            productCard: 'Products - Product Card'
        },
        labels: {
            sortSortClick: 'Products - Sort - Sort Click',
            productCardPlus: 'Products - Product Card - Plus Button',
            productCardMinus: 'Products - Product Card - Minus Button',
            productCardQuantityInput: 'Products - Product Card - Quantity Input',
            productCardAddToCart: 'Products - Product Card - Add to Cart Button'
        }
    },
    cart: {
        categories: {
            cart: 'Cart'
        },
        actions: {
            view: 'Cart - View',
            productCard: 'Cart - Product Card'
        },
        labels: {
            cartViewContinueToCheckout: 'Cart - Cart View - Continue to Checkout',
            cartViewClearAllCarts: 'Cart - Cart View - Clear All Carts',
            cartViewChangeDeliveryDate: 'Cart - Cart View - Change Delivery Date',
            productCardPlus: 'Cart - Product Card - Plus Button',
            productCardMinus: 'Cart - Product Card - Minus Button',
            productCardQuantityInput: 'Cart - Product Card - Quantity Input',
            productCardDelete: 'Cart - Product Card - Delete Button'
        }
    },
    minicart: {
        categories: {
            minicart: 'Minicart'
        },
        actions: {
            continueToCart: 'Minicart - Continue to Cart',
            clearAllCarts: 'Minicart - Clear All Carts',
            changeDeliveryDate: 'Minicart - Change Delivery Date',
            productCard: 'Minicart - Product Card',
            settings: 'Minicart - Settings'
        },
        labels: {
            continueToCart: 'Minicart - Continue to Cart - Continue to Cart',
            clearAllCarts: 'Minicart - Clear All Carts - Clear All Carts',
            changeDeliveryDate: 'Minicart - Change Delivery Date - Change Delivery Date',
            productCardPlus: 'Minicart - Product Card - Plus Button',
            productCardMinus: 'Minicart - Product Card - Minus Button',
            productCardQuantityInput: 'Minicart - Product Card - Quantity Input',
            productCardDelete: 'Minicart - Product Card - Delete Button',
            settingsAccountSettings: 'Minicart - Settings - Account Settings'
        }
    },
    home: {
        categories: {
            home: 'Homepage'
        },
        actions: {
            myRecentOrders: 'Homepage - My Recent Orders',
            favoriteProductCard: 'Homepage - Favorite Product Card'
        },
        labels: {
            myRecentOrdersReorder: 'Homepage - My Recent Orders - Reorder',
            favoriteProductCardPlus: 'Homepage - Favorite Product Card - Plus Button',
            favoriteProductCardMinus: 'Homepage - Favorite Product Card - Minus Button',
            favoriteProductCardQuantityInput: 'Homepage - Favorite Product Card - Quantity Input',
            favoriteProductCardAddToCart: 'Homepage - Favorite Product Card - Add to Cart Button'
        }
    },
    orderHistory: {
        categories: {
            orderInformation: 'Order Information'
        },
        actions: {
            orderInformationOrderHistory: 'Order Information - Order History'
        },
        labels: {
            orderInformationOrderHistoryReorder: 'Order Information - Order History - Reorder'
        }
    },
    order: {
        categories: {
            order: 'Order'
        },
        actions: {
            details: 'Order - Details'
        },
        labels: {
            detailsReorder: 'Order - Order Details - Reorder'
        }
    },
    footer: {
        categories: {
            footer: 'Footer'
        },
        actions: {
            chat: 'Footer - Chat'
        },
        labels: {
            chatStartChat: 'Footer - Chat - Start Chat'
        }
    }
};

export const buttonClickEvent = (action = '', label = '', category = '') => {
    if (!ga4enabled) {
        return;
    }

    const buttonClickEventData = {
        event: 'button_click',
        event_category: category,
        event_action: action,
        event_label: label
    };

    pushDataLayerEventData(buttonClickEventData);
};
