import React from 'react';
import classNames from 'classnames/bind';

import * as styles from '../../OrderTemplate/OrderTemplate.css';
import { GENERIC_L10N } from '../../../../common/Constants';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../../components/core/Button/Button';
import Icon from '../../../../components/core/Icon/Icon';
import { getExcel } from '../../../../actions/OrderTemplateAction';

const cx = classNames.bind(styles);
export const ExcelUpload = ({ submitExcel }) => {
    const localization = useSelector(state => state.pageContent.localization);


    const dispatch = useDispatch();
    return (
        <div className={cx('orderTempleft', 'clearfix')}>
            <div className={cx('orderControlsGroup', 'excelUploadControlGroup')}>
                <div className={cx('totalOrder', 'col6', 'sm6')}></div>
                <div className={cx('orderTempright', 'col6', 'sm6')}>
                    <Button
                        automation-id='at-download-catalogue-button'
                        className={cx('create-new')}
                        type='button'
                        buttonType='Primary'
                        onClick={() => {
                            dispatch(getExcel());
                        }}
                    >
                        {localization[GENERIC_L10N.CATALOGUE]}
                        <Icon iconType='svg' width='15' height='15' viewBox='0 0 15 15' name='ic-download' />
                    </Button>
                </div>
            </div>

            <div
                className={cx('uploadOrderTemplateForm', 'excelUploadControlGroup')}
                id='dropContainer'
                onDragOver={e => e.preventDefault()}
                onDrop={e => {
                    document.getElementById('filesInput').files = e.dataTransfer.files;
                    e.preventDefault();
                    submitExcel(e.dataTransfer.files);
                }}
            >
                <form id='uploadTemplateId' onSubmit={e => {}}>
                    <Icon iconType='svg' width='40.203' height='34.46' viewBox='0 0 40.203 34.46' name='ic-upload' />
                    <div>
                        <span>{localization[GENERIC_L10N.DRAG_DROP_TITLE]}</span>
                        <div>
                            <label htmlFor='filesInput' className={cx('btn')}>
                                {localization[GENERIC_L10N.BROWSE]}
                            </label>
                            <input
                                id='filesInput'
                                accept='.xls'
                                type='file'
                                className={cx('browseFileInput')}
                                onChange={e => {
                                    submitExcel(e.currentTarget.files);
                                    e.target.value = null;
                                }}
                            />
                        </div>
                    </div>
                    <label className={cx('supportLabel')}>{localization[GENERIC_L10N.SUPPORT_FILES]}</label>
                </form>
            </div>
        </div>
    );
};
