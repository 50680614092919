import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { URL_PATHS, IMAGE_FORMAT_TYPES } from '../common/Constants';

const favoritesProducts = (val, data) => {
    const products = val.map(item => ({
        name: item.name,
        baseUnit: item.baseUnit,
        imageUrl: (_find(item.images, { format: IMAGE_FORMAT_TYPES.PRODUCT }) || {}).url,
        sku: {
            label: 'SKU',
            id: `${Number(item.codeShort)}`,
            code: item.code,
            price: item.price && {
                formattedPrice:item.price.formattedValue
            }
        },
        units: item.availableUnits.map(val => ({
            id: val.id,
            label: val ? val.description.toUpperCase() : val.description,
            value: val.description
        })),
       conversionFactors: item.conversionFactors.map(val => ({
        code: val.code,
        value: val.value
       })),
        isFav: item.favourite,
        productUrl: `${URL_PATHS.PDP}/${item.code}`,
        depositProduct: item.depositProduct,
        depositProductLogoUrl: item.depositProductLogoUrl,
        isPromoProduct: !_isEmpty(item.promotion),
        promotion: item.promotion && {
            description: item.promotion.description,
            startDate: item.promotion.formattedStartDate,
            endDate: item.promotion.formattedEndDate,
            note: item.promotion.note,
            conditionalGroup: _get(item, 'promotion.promotionMechanic.conditionalGroups', []),
            freeGroup: _get(item, 'promotion.promotionMechanic.freeGroup', [])
        },
        shippingInfo: '',
        description: item.description,
        waterCategory: data.trackAndTraceEnabled  ? item.waterCategory : false,
        stockFlag: item.stockFlag,
        stockNotified: item.stockNotified
    }));

    return products;
};
export default favoritesProducts;
