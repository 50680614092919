import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import { bindActionCreators } from 'redux';
import Link from 'react-router/lib/Link';
import SelectBox from '../../../components/core/SelectBox/SelectBox';
import Button from '../../../components/core/Button/Button';
import {
    getOrderList,
    cancelOrderRequest,
    clearOrderForm,
    updateOrderId,
    updateProcessType,
    updateCreationPeriod,
    resetOrderList
} from '../../../actions/OrderListAction';
import Reorder from '../../../components/site/Reorder/Reorder';
import * as styles from './OrderList.css';
import { URL_PATHS, PAGE_ID, ORDERS_L10N, PAGINATION_L10N, GENERIC_L10N } from '../../../common/Constants';
import { isIndirectUser } from '../../../common/UserManager/UserManager';
import { formatHungaryTousends, isValidOrderList } from '../../../common/Utils/Utils';
import { getPageContent } from '../../../actions/PageContentAction';
import Layout from '../../../components/site/Layout/Layout';
import Pagination from '../../../components/core/Pagination/Pagination';
import Modal from '../../../components/core/Modal/Modal';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import Icon from '../../../components/core/Icon/Icon';
const cx = classNames.bind(styles);

export class OrderList extends Component {
    static propTypes = {
        embeded: PropTypes.bool,
        orderListData: PropTypes.objectOf(PropTypes.any),
        localization: PropTypes.objectOf(PropTypes.string),
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static defaultProps = {
        embeded: false,
        orderListData: {},
        localization: {},
        commonLocalization: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            addClass: false,
            showModal: false,
            modalTitle: '',
            cancelOrderId: ''
        };
        if (!this.props.embeded) {
            this.props.actions.getPageContent({pageId: PAGE_ID.ORDER_LIST});
        } else {
            const params = this.getQueryParams();
            this.props.actions.getOrderList(params);
        }
    }

    componentWillUnmount() {
        if (!isValidOrderList(this.props.orderListData)) {
            this.props.actions.resetOrderList();
        }
    }

    onPeroidChangeHandler = e => {
        this.props.actions.updateCreationPeriod(e);
    };

    onProcessTypeChangeHandler = e => {
        this.props.actions.updateProcessType(e);
    };

    onOrderIdChangeHandler = e => {
        this.props.actions.updateOrderId(e.target.value);
        this.setState({ orderId: e.target.value });
    };

    onSubmitHandler = (event, mEvent) => {
        event.preventDefault();
        const params = this.getQueryParams();
        this.props.actions.getOrderList(params);
        if (mEvent) {
            const { addClass } = this.state;
            this.setState({ addClass: !addClass });
        }
    };

    onClearHandler = () => {
        this.setState({ orderId: '' });
        this.props.actions.clearOrderForm();
        this.props.actions.getOrderList();
    };

    onCancelOrderHandler = orderId => {
        this.setState({
            showModal: true,
            modalTitle: `${this.props.localization[ORDERS_L10N.CANCEL_ORDER_POPUP_TITLE]}: ${orderId}`,
            cancelOrderId: orderId
        });
    };

    onCancelClick = () => {
        const { cancelOrderId } = this.state;
        this.setState({ showModal: false });
        this.props.actions.cancelOrderRequest(cancelOrderId);
    };

    onFilterChangeHandler = type => {
        this.props.orderListData.currentStatusType = type;
        const params = this.getQueryParams();
        this.props.actions.getOrderList(params);
    };

    onQuickLinkHandler = id => {
        const listItem = document.getElementById(id);
        if (listItem) {
            listItem.classList.toggle('OrderList-expandedListItem');
        }
    };

    onPaginationHandler = (type, val) => {
        let params;
        if (type === 'itemSize') {
            params = this.getQueryParams(0, val);
        } else {
            params = this.getQueryParams(val - 1, this.props.orderListData.pagination.pageSize);
        }
        this.props.actions.getOrderList(params);
    };

    /**
     * @brief      Renders the page content.
     *
     * @return     { JSX }.
     */
     getContent() {
        const { orderListData, localization, commonLocalization, embeded } = this.props;
        const { pagination = {} } = this.props.orderListData;
        const currentPage = pagination.currentPage || 0;
        const defaultCloseBtn = false;
        const { showModal } = this.state;
        const isPricingAvailable = !!_get(orderListData, 'orders[0].total');

        return (
            <>
                {!_isEmpty(orderListData) && !_isEmpty(localization) && (
                    <>
                        <div className={cx('historysearch', 'clearfix')}>
                            {!embeded && (
                                <h3 automation-id='at-order-history-title-text' className={cx('clearfix')}>
                                    <span>
                                        <Icon width='32px' height='32px' viewBox='0 0 32 32' name='order-history' />
                                        {localization[ORDERS_L10N.ORDER_HISTORY_TITLE]}
                                    </span>
                                </h3>
                            )}
                            <div
                                automation-id='at-search-my-order-history-section'
                                className={cx(`${this.state.addClass ? 'openSearchModal' : ''}`)}
                            >
                                <div className={cx('searchinner', 'clearfix')}>
                                    <Button
                                        automation-id='at-close-search-icon'
                                        id='closeIconId'
                                        aria-label='close icon'
                                        className={cx('closeSearch')}
                                        role='button'
                                        tabIndex='0'
                                        onClick={this.toggle}
                                        onKeyDown={this.toggle}
                                    >
                                        <Icon
                                            iconType='svg'
                                            width='24'
                                            height='25'
                                            viewBox='0 0 24 25'
                                            name='close-gray'
                                        />
                                    </Button>
                                    <h4>{localization[ORDERS_L10N.SEARCH_ORDER_HISTORY]}</h4>
                                    <div className={cx('srchrow')}>
                                        <div className={cx('input', 'col3', 'md6', 'sm12')}>
                                            <input
                                                automation-id='at-order-id-textfield'
                                                className={cx('inputText')}
                                                type='text'
                                                placeholder={localization[ORDERS_L10N.ORDER_ID]}
                                                onChange={this.onOrderIdChangeHandler}
                                                value={orderListData.orderId}
                                            />
                                        </div>
                                        <div className={cx('input', 'col3', 'md6', 'sm12')}>
                                            <div className={cx('custom-seelct', 'periods')}>
                                                <SelectBox
                                                    automation-id='at-creation-period-dropdown'
                                                    id='period'
                                                    onChange={this.onPeroidChangeHandler}
                                                    defaultValue={orderListData.currentPeriod}
                                                    options={orderListData.periods}
                                                />
                                            </div>
                                        </div>
                                        <div className={cx('input', 'col3', 'md6', 'sm12')}>
                                            <div className={cx('custom-seelct')}>
                                                <SelectBox
                                                    id='type'
                                                    automation-id='at-process-type-dropdown'
                                                    onChange={this.onProcessTypeChangeHandler}
                                                    defaultValue={orderListData.currentProcessType}
                                                    options={orderListData.processTypes}
                                                />
                                            </div>
                                        </div>
                                        <div className={cx('submit', 'col3', 'md6', 'sm12')}>
                                            <Button
                                                className={cx('hiddenSm')}
                                                automationId='at-search-button'
                                                id='orderListSearchButton'
                                                buttonType='Primary'
                                                type='submit'
                                                onClick={this.onSubmitHandler}
                                            >
                                                {commonLocalization[GENERIC_L10N.SEARCH_BTN]}
                                            </Button>
                                            <Button
                                                className={cx('hiddenLg', 'hiddenMd', 'hiddenXl')}
                                                automationId='at-search-button'
                                                id='orderSearchButton'
                                                buttonType='Primary'
                                                type='submit'
                                                onClick={e => this.onSubmitHandler(e, true)}
                                            >
                                                {commonLocalization[GENERIC_L10N.SEARCH_BTN]}
                                            </Button>
                                            <Button
                                                automation-id='at-clear-button'
                                                id='orderListClearButton'
                                                className={cx('btn-clr')}
                                                type='submit'
                                                onClick={this.onClearHandler}
                                            >
                                                {commonLocalization[GENERIC_L10N.CLEAR_BTN]}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={cx('orderHistory', 'clearfix')}>
                            <div className={cx('resultPanel', 'hiddenLg', 'hiddenMd', 'hiddenXl')}>
                                <div className={cx('resultCount')}>
                                    {localization[ORDERS_L10N.RESULTS_TEXT]}{' '}
                                    <span>({pagination && pagination.totalResults})</span>
                                </div>
                                <div className={cx('resultOrder')}>
                                    <Button
                                        automation-id='at-search-link'
                                        role='presentation'
                                        id='searchIconId'
                                        onClick={this.toggle}
                                    >
                                        {localization[ORDERS_L10N.SEARCH_ORDERS_TEXT]}{' '}
                                        <Icon
                                            iconType='svg'
                                            width='20'
                                            height='20'
                                            viewBox='0 0 20 20'
                                            name='search-icon'
                                        />
                                    </Button>
                                </div>
                            </div>

                            <div className={cx('historyStatuslink', 'clearfix')}>
                                {/*  <div className={cx('label')}> TOTAL : </div> <span className={cx('orderCount')}>{orderListData.totalOrders} Orders</span>*/}
                                <ul className={cx('statusLink')}>
                                    {orderListData.statusTypesList &&
                                        orderListData.statusTypesList.map(item => {
                                            return (
                                                <li
                                                    key={item.value}
                                                    className={cx(
                                                        `${
                                                            item.value === orderListData.currentStatusType
                                                                ? 'active'
                                                                : ''
                                                        }`
                                                    )}
                                                >
                                                    <span
                                                        automation-id={`at-${item.label
                                                            .toString()
                                                            .toLowerCase()}-filter-button`}
                                                        role='presentation'
                                                        id='filterLink'
                                                        onClick={() => this.onFilterChangeHandler(item.value)}
                                                    >
                                                        {item.label}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                </ul>
                                <div className={cx('toppaginations', 'hiddenSm')}>
                                    {pagination && pagination.totalPages > 0 && (
                                        <Pagination
                                            automation-id='at-page-number-dropdown'
                                            currentPage={currentPage + 1}
                                            totalPage={pagination.totalPages}
                                            onChange={e => this.onPaginationHandler('page', e)}
                                            pageText={commonLocalization[GENERIC_L10N.PAGE]}
                                            itemsText={commonLocalization[GENERIC_L10N.ITEMS]}
                                            ofText={localization[PAGINATION_L10N.OF]}
                                            pageSize={pagination.pageSize}
                                            itemsOptions={pagination.itemsPerPage}
                                            itemOnChange={e => this.onPaginationHandler('itemSize', e)}
                                            renderType='List'
                                            themeSelectBox={cx('themeSelectBox')}
                                            arrowTheme={cx('arrowTheme', 'down')}
                                            paginationSelect={cx('paginationSelect')}
                                            customListPage={cx('customListPage')}
                                        />
                                    )}
                                </div>
                            </div>
                            <div
                                automation-id='at-order-list-table-view'
                                className={cx('historyhistoryList', 'clearfix')}
                            >
                                <ul>
                                    <li className={cx('historylistheader', 'hiddenSm')}>
                                        <div
                                            automation-id='at-delivery-date-title-text'
                                            className={cx('th-data', 'col2')}
                                        >
                                            {localization[ORDERS_L10N.DELIVERY_DATE]}
                                        </div>
                                        <div
                                            automation-id='at-order-number-title-text'
                                            className={cx('th-data', 'col2')}
                                        >
                                            {localization[ORDERS_L10N.ORDER_NUMER]}
                                        </div>
                                        <div
                                            automation-id='at-total-item-title-text'
                                            className={cx('th-data', 'col1', 'Calign')}
                                        >
                                            {localization[ORDERS_L10N.TOTAL_ITEMS]}
                                        </div>
                                        {isPricingAvailable && (
                                            <div
                                                automation-id='at-order-total-title-text'
                                                className={cx('th-data', 'col2', 'Calign')}
                                            >
                                                {localization[ORDERS_L10N.ORDER_TOTAL]}
                                            </div>
                                        )}
                                        <div
                                            automation-id='at-description-title-text'
                                            className={cx('th-data', 'col2', 'Lalign')}
                                        >
                                            {localization[ORDERS_L10N.DESCRIPTION_TEXT]}
                                        </div>
                                        <div className={cx('th-data', 'col2')}>&nbsp;</div>
                                        <div className={cx('th-data', 'col1')}>&nbsp;</div>
                                    </li>
                                    {orderListData.orders && orderListData.orders.length > 0 ? (
                                        orderListData.orders.map(item => {
                                            return (
                                                <li
                                                    id={item.code}
                                                    className={cx('historylistItem', 'clearfix')}
                                                    key={item.code}
                                                >
                                                    <div className={cx('td-data', 'col2', 'sm12', 'td-date')}>
                                                        <div
                                                            automation-id='at-delivery-date-title-text'
                                                            className={cx(
                                                                'listItemhead',
                                                                'hiddenLg',
                                                                'hiddenMd',
                                                                'hiddenXl',
                                                                'sm5'
                                                            )}
                                                        >
                                                            {localization[ORDERS_L10N.DELIVERY_DATE]}
                                                        </div>
                                                        <div
                                                            automation-id='at-delivery-date-details-text'
                                                            className={cx('Item-data', 'sm7')}
                                                        >
                                                            <span className={cx('hiddenSm')}>
                                                                {item.formattedDeliveryDate}
                                                            </span>
                                                            <span className={cx('hiddenLg', 'hiddenMd', 'hiddenXl')}>
                                                                <Link
                                                                    automation-id='at-order-number-link'
                                                                    to={`${URL_PATHS.ORDER_DETAILS}/${item.code}`}
                                                                >
                                                                    {item.formattedDeliveryDate}{' '}
                                                                </Link>
                                                            </span>
                                                        </div>
                                                        <div
                                                            automation-id='at-quickview-link-dropdown'
                                                            className={cx('quickViewLink')}
                                                        >
                                                            <span
                                                                id='quickLinkId'
                                                                className={cx('quickViewTxt', 'pointer')}
                                                                role='presentation'
                                                                onClick={() => this.onQuickLinkHandler(item.code)}
                                                            >
                                                                {localization[ORDERS_L10N.QUICKVIEW_LINK]}
                                                                <Icon
                                                                    className={cx('downArrow')}
                                                                    iconType='svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    name='downarrow'
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={cx(
                                                            'td-data',
                                                            'col2',
                                                            'sm12',
                                                            'Itemshow',
                                                            'firstItem'
                                                        )}
                                                    >
                                                        <div
                                                            automation-id='at-order-number-title-text'
                                                            className={cx(
                                                                'listItemhead',
                                                                'hiddenLg',
                                                                'hiddenMd',
                                                                'hiddenXl',
                                                                'sm5'
                                                            )}
                                                        >
                                                            {localization[ORDERS_L10N.ORDER_NUMER]}
                                                        </div>
                                                        <div className={cx('Item-data', 'sm7')}>
                                                            <span className={cx('hiddenSm')}>
                                                                <Link
                                                                    automation-id='at-order-number-link'
                                                                    to={`${URL_PATHS.ORDER_DETAILS}/${item.code}`}
                                                                    className={cx('orderno')}
                                                                >
                                                                    {item.code}
                                                                </Link>
                                                            </span>
                                                            <span className={cx('hiddenLg', 'hiddenMd', 'hiddenXl')}>
                                                                {' '}
                                                                {item.code}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={cx('td-data', 'col1', 'sm12', 'Calign', 'Itemshow')}
                                                    >
                                                        <div
                                                            automation-id='at-total-item-title-text'
                                                            className={cx(
                                                                'listItemhead',
                                                                'hiddenLg',
                                                                'hiddenMd',
                                                                'hiddenXl',
                                                                'sm5'
                                                            )}
                                                        >
                                                            {localization[ORDERS_L10N.TOTAL_ITEMS]}
                                                        </div>
                                                        <div
                                                            automation-id='at-total-item-details-text'
                                                            className={cx('Item-data', 'sm7')}
                                                        >
                                                            {item.totalItems}
                                                        </div>
                                                    </div>
                                                    {isPricingAvailable && (
                                                        <div
                                                            className={cx(
                                                                'td-data',
                                                                'col2',
                                                                'sm12',
                                                                'Calign',
                                                                'Itemshow'
                                                            )}
                                                        >
                                                            <div
                                                                automation-id='at-order-total-title-text'
                                                                className={cx(
                                                                    'listItemhead',
                                                                    'hiddenLg',
                                                                    'hiddenMd',
                                                                    'hiddenXl',
                                                                    'sm5'
                                                                )}
                                                            >
                                                                {localization[ORDERS_L10N.ORDER_TOTAL]}
                                                            </div>
                                                            <div
                                                                automation-id='at-order-total-details-text'
                                                                className={cx('Item-data', 'sm7')}
                                                            >
                                                                {formatHungaryTousends(item.total.formattedValue)}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div
                                                        className={cx(
                                                            'td-data',
                                                            'col2',
                                                            'sm12',
                                                            'Lalign',
                                                            'Itemshow',
                                                            'lastItem'
                                                        )}
                                                    >
                                                        <div
                                                            automation-id='at-description-title-text'
                                                            className={cx(
                                                                'listItemhead',
                                                                'hiddenLg',
                                                                'hiddenMd',
                                                                'hiddenXl',
                                                                'sm5'
                                                            )}
                                                        >
                                                            {localization[ORDERS_L10N.DESCRIPTION_TEXT]}
                                                        </div>
                                                        <div
                                                            automation-id='at-description-details-text'
                                                            className={cx(
                                                                'des-data',
                                                                'hiddenLg',
                                                                'hiddenMd',
                                                                'hiddenXl',
                                                                'sm7'
                                                            )}
                                                        >
                                                            {item.description}
                                                        </div>
                                                        <a
                                                            href='#'
                                                            className={cx(
                                                                'chevron-drop',
                                                                'hiddenSm',
                                                                _get(item, 'description', '').length > 18 && 'long-desc'
                                                            )}
                                                            onClick={e => this.toggleDesc(e, item.code)}
                                                        >
                                                            <div className={cx('chevron-content')}>
                                                                <div className={cx('des-data', 'sm7')}>
                                                                    {item.description}
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className={cx('td-data', 'reorder-btn', 'col2', 'sm12')}>
                                                        {item.isReOrderable && (
                                                            <Reorder
                                                                automation-id='at-reorder-button'
                                                                orderId={item.code}
                                                                customReorderStyle={cx('btnReorder')}
                                                                buttonValue={localization[ORDERS_L10N.REORDER_BTN]}
                                                                automationId={`Reorder-${item.code}`}
                                                                modalLocalization={{
                                                                    cancelBtn: localization[ORDERS_L10N.REORDER_POPUP_CANCEL_BUTTON],
                                                                    checkoutBtn: localization[ORDERS_L10N.REORDER_POPUP_CHECKOUT_BUTTON],
                                                                    header: localization[ORDERS_L10N.REORDER_POPUP_HEADER_TEXT],
                                                                    subtext: localization[ORDERS_L10N.REORDER_POPUP_SUBTEXT],
                                                                    warning: localization[ORDERS_L10N.REORDER_POPUP_SUBTEXT_WARNING],
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <div
                                                        className={cx(
                                                            'td-data',
                                                            'cancel-btn',
                                                            'col1',
                                                            'sm12',
                                                            'Calign'
                                                        )}
                                                    >
                                                        {(item.isReOrderable || item.isCancellable) && (
                                                            <span
                                                                automation-id='at-three-dots-icon'
                                                                className={cx('hrtripledot', 'hiddenSm')}
                                                            >
                                                                ...
                                                            </span>
                                                        )}
                                                        {item.isCancellable && (
                                                            <button
                                                                type='button'
                                                                automation-id='at-cancel-order-button'
                                                                id='cancelIconId'
                                                                className={cx('btn-del')}
                                                                title={commonLocalization[GENERIC_L10N.CANCEL_BTN]}
                                                                onClick={() => this.onCancelOrderHandler(item.code)}
                                                            >
                                                                <span className={cx('cancelIcon')}>
                                                                    <Icon
                                                                        automation-id='at-cancel-order-icon'
                                                                        iconType='svg'
                                                                        width='24'
                                                                        height='25'
                                                                        viewBox='0 0 24 25'
                                                                        name='close-gray'
                                                                    />
                                                                </span>
                                                                <span className={cx('cancelTxt')}>
                                                                    {' '}
                                                                    {commonLocalization[GENERIC_L10N.CANCEL_BTN]}
                                                                </span>
                                                            </button>
                                                        )}
                                                    </div>
                                                </li>
                                            );
                                        })
                                    ) : (
                                        <li automation-id='at-no-orders-found-text' className={cx('noResults')}>
                                            <Icon
                                                className={cx('noresultIcon')}
                                                iconType='svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                name='no-results'
                                            />{' '}
                                            {commonLocalization[GENERIC_L10N.NO_RESULTS_MSG]}
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className={cx('bottomcounts', 'clearfix')}>
                                <div className={cx('pagger')}>
                                    {pagination && pagination.totalPages > 0 && (
                                        <Pagination
                                            customPageWrapper={cx('customPageWrapper')}
                                            id='paginationId'
                                            pageSize={pagination.pageSize}
                                            itemsOptions={pagination.itemsPerPage}
                                            pageText={commonLocalization[GENERIC_L10N.PAGE]}
                                            itemsText={commonLocalization[GENERIC_L10N.ITEMS]}
                                            ofText={localization[PAGINATION_L10N.OF]}
                                            itemOnChange={e => this.onPaginationHandler('itemSize', e)}
                                            themeSelectBox={cx('themeSelectBox')}
                                            arrowTheme={cx('arrowTheme', 'down')}
                                            paginationSelect={cx('paginationSelect')}
                                            customListPage={cx('customListPage')}
                                            currentPage={currentPage + 1}
                                            totalPage={pagination.totalPages}
                                            onClick={e => this.onPaginationHandler('page', e)}
                                        />
                                    )}
                                </div>
                                <span automation-id='at-number-of-orders' className={cx('hiddenSm', 'Items')}>
                                    {pagination && pagination.totalResults}{' '}
                                    {localization[ORDERS_L10N.NO_OF_ORDERS_TEXT]}
                                </span>
                            </div>
                        </div>
                    </>
                )}
                {showModal && (
                    <Modal
                        automation-id='at-reject-order-confirmation-popup'
                        defaultCloseBtn={defaultCloseBtn}
                        showModal={showModal}
                        modalTheme={cx('themeModal')}
                        modalTitleTheme={cx('modalTitleTheme')}
                        themeContent={cx('themeContent')}
                        themeClose={cx('themeClose')}
                        themeModalBlock={cx('themeModalBlock')}
                        title={this.state.modalTitle}
                        onClose={this.modalOnClose}
                    >
                        {this.renderConfirmationPopup()}
                    </Modal>
                )}
            </>
        );
    }

    modalOnClose = () => {
        this.setState({ showModal: false });
    };

    toggle = () => {
        const { addClass } = this.state;
        this.setState({ addClass: !addClass });
    };

    getQueryParams = (page, itemSize) => {
        const params = {
            orderId: this.state.orderId,
            creationPeriod: this.props.orderListData.currentPeriod,
            processType: this.props.orderListData.currentProcessType,
            statusType: this.props.orderListData.currentStatusType,
            pageNumber: page,
            pageItemSize: itemSize
        };
        return params;
    };

    renderConfirmationPopup = () => {
        const { localization } = this.props;

        return (
            <div className={cx('RestoreModalContainer')}>
                <div className={cx('saleMClose', 'hiddenLg', 'hiddenMd', 'hiddenXl')}>
                    <Button automation-id='at-cancel-button' type='button' size='Sm' onClick={this.modalOnClose}>
                        <Icon iconType='svg' width='24' height='25' viewBox='0 0 24 25' name='close-gray' />
                    </Button>
                </div>
                <div className={cx('restoreModalTitle')}>
                    <p automation-id='at-reject-order-confirmation-popup-text'>
                        {localization[ORDERS_L10N.CANCEL_ORDER_POPUP_DESCRIPTION_TEXT]}
                    </p>
                </div>
                <div>
                    <Button
                        automation-id='at-reject-button'
                        id='rejectButton'
                        className={cx('restoreButton')}
                        type='button'
                        size='Sm'
                        buttonType='Primary'
                        onClick={this.onCancelClick}
                    >
                        {localization[ORDERS_L10N.REJECT_BTN]}
                    </Button>
                    <Button
                        automation-id='at-cancel-button'
                        id='cancelButton'
                        className={cx('cancelButton')}
                        type='button'
                        size='Sm'
                        onClick={this.modalOnClose}
                    >
                        {localization[ORDERS_L10N.CANCEL_BTN]}
                    </Button>
                </div>
            </div>
        );
    };

    toggleDesc = (e, liId) => {
        e.preventDefault();
        if (e.currentTarget.classList.contains('OrderList-long-desc')) {
            document.getElementById(liId).classList.toggle('OrderList-show-long-desc');
        }
    };


    /**
     * @brief      Renders the component as part of another page (in a tab for example)
     *
     * @return     { JSX }
     */
    embedableMode() {
        return (
            <div automation-id='at-order-history-page' className={cx('historypanel', 'clearfix')}>
                {this.getContent()}
            </div>
        );
    }

    /**
     * @brief      Renders the component as full page layout
     *
     * @return     { JSX }
     */
    fillPageMode() {
        const { orderListData } = this.props;
        return (
            <Layout showDashboardHeader showOutlet pageId={PAGE_ID.ORDER_LIST}>
                <div automation-id='at-order-history-page' className={cx('historypanel', 'clearfix')}>
                    <BreadCrumbs breadcrumbValues={orderListData?.breadcrumbs} />
                    {this.getContent()}
                </div>
            </Layout>
        );
    }

    render() {
        const { embeded } = this.props;

        return <>{embeded ? this.embedableMode() : this.fillPageMode()}</>;
    }
}

const mapStateToProps = state => {
    return {
        orderListData: state.orderListData,
        orderId: state.orderListData.orderId,
        localization: _get(state, 'pageContent.localization'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                getOrderList,
                cancelOrderRequest,
                clearOrderForm,
                updateOrderId,
                updateProcessType,
                updateCreationPeriod,
                resetOrderList,
                getPageContent
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
