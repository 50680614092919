import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import classNames from 'classnames/bind';
import { COMMON_COMPONENT } from '../../../common/Constants';
import * as styles from './CreditLimit.css';
import { getCreditInfoDetails } from '../../../actions/CreditInfoAction';
import SmartEdit from '../SmartEdit/SmartEdit'
import { formatHungaryTousends } from '../../../common/Utils/Utils';
import {CreditIssueNotificationStatus} from "./Status/CreditIssueNotificationStatus";

const cx = classNames.bind(styles);

export function CreditLimit(props) {
    const creditInformation = useSelector(store => store.creditInformation);
    const headerData = useSelector(store => store.pageContent.headerData);
    const dispatch = useDispatch();

    const hasCreditIssue = creditInformation && creditInformation.creditLimitNotification

    const { theme = 'creditBalance' } = props;
    const creditLimtComp = headerData[COMMON_COMPONENT.CREDIT_LIMIT] || {};

    const isMobileCreditIssueBar = theme === 'phoneCreditBalance';
    useEffect(() => {
        if (!_isEmpty(creditLimtComp) && _isEmpty(creditInformation) && (theme === 'creditBalance' || isMobileCreditIssueBar)) {
            // props.actions.getCreditInfoDetails(true);
            dispatch(getCreditInfoDetails(true));
        }
    }, [creditLimtComp]);

    const balanceLabel = <h6 className={cx('balance')}>{creditLimtComp.name}</h6>
    const balanceValue = <a className={cx('price')}>{formatHungaryTousends(_get(creditInformation, 'creditUsed.formattedValue', ''))}</a>
    return (
        <li automation-id='at-credit-information' className={cx(`${theme}`)}>
            {_get(creditInformation, 'isCreditInfoVisible', false) && (
                <SmartEdit data={creditLimtComp}>
                    <p>
                        {balanceValue}
                        {(isMobileCreditIssueBar && hasCreditIssue) && <CreditIssueNotificationStatus />}
                    </p>
                    {( isMobileCreditIssueBar || !hasCreditIssue) && balanceLabel}
                    {(!isMobileCreditIssueBar && hasCreditIssue) && <CreditIssueNotificationStatus />}
                </SmartEdit>
            )}
        </li>
    );
}

// CreditLimit.propTypes = {
//     creditInformation: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
//     headerData: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object])
// };

// CreditLimit.defaultProps = {
//     creditInformation: {},
//     headerData: {}
// };

// const mapStateToProps = state => {
//     return {
//         creditInformation: _get(state, 'creditInformation'),
//         headerData: _get(state, 'pageContent.headerData')
//     };
// };

// const mapDispatchToProps = dispatch => ({
//     actions: bindActionCreators(
//         {
//             getCreditInfoDetails
//         },
//         dispatch
//     )
// });

export default CreditLimit;
