import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import isEmpty from 'lodash/isEmpty';
import * as styles from './OrderTemplate.css';
import Button from '../../../components/core/Button/Button';
import Modal from '../../../components/core/Modal/Modal';
import Layout from '../../../components/site/Layout/Layout';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import Pagination from '../../../components/core/Pagination/Pagination';
import { getPageContent } from '../../../actions/PageContentAction';
import RestoreModalContent from '../../../components/site/RestoreModalContent/RestoreModalContent';
import DeleteTemplateModalContent from '../../../components/site/DeleteTemplateModalContent/DeleteTemplateModalContent';
import CreateNewTemplate from '../../../components/site/CreateNewTemplate/CreateNewTemplate';
import { getOrderTemplateDetails, getExcel, postExcel, resetExcelErrors } from '../../../actions/OrderTemplateAction';
import {
    URL_PATHS,
    PAGE_ID,
    TEMP_L10N,
    PAGINATION_L10N,
    PAGE_ITEM_SIZE,
    GENERIC_L10N
} from '../../../common/Constants';

import {
    getInvoicePDF,
} from '../../../actions/InvoicesAction';

import {
    getExcelOrderUpload
} from '../../../common/UserManager/UserManager'


import Icon from '../../../components/core/Icon/Icon';
import OrderTemplateModal from '../../../components/site/OrderTemplateModal/OrderTemplateModal';

const cx = classNames.bind(styles);

export class OrderTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalData: false,
            copyModalData: null,
            deleteModalData: false,
            excelOrderUpload: getExcelOrderUpload(),
        };
    }

    componentDidMount() {
        this.props.actions.getPageContent({
            pageId: PAGE_ID.TEMPLATE_LIST,
            pageNumber: 0,
            pageSize: PAGE_ITEM_SIZE
        });
    }

    OnClose = () => {
        this.setState({ copyModalData: null });
        this.setState({ showModal: false });
    };

    handleCreateNewTemplate = () => {
        this.setState({ copyModalData: null });
        this.setState({ showModal: true });
    };

    handleRestoreClick = row => {
        const seletedTemp = {
            name: row.name,
            id: row.code,
            qty: row.totalItems,
            totalQty: 0,
            products: []
        };

        if (row.entries && row.entries.length) {
            for (let i = 0; i < row.entries.length; i += 1) {
                if (row.entries[i].quantity > 0)
                    seletedTemp.products.push({
                        productCode: row.entries[i].product.code,
                        quantity: `${row.entries[i].quantity}`,
                        unitCode: row.entries[i].qtyType
                    });
            }

            seletedTemp.totalQty = row.entries.reduce((total, obj) => total + obj.quantity, 0);
        }
        this.setState({ modalData: seletedTemp });
    };

    modalOnClose = () => {
        this.setState({ modalData: null });
    };

    renderRestoreTemplate = row => {
        const { localization } = this.props;
        return (
            <Button
                automation-id='at-add-to-card-button'
                className={cx('restoreButton', 'iconAlignRestore')}
                type='button'
                buttonType='Primary'
                onClick={() => this.handleRestoreClick(row)}
            >
                {/* <Icon iconType='svg' width='25' height='24' viewBox='0 0 25 24' name='restore' /> */}
                {localization[TEMP_L10N.RESTORE_BTN]}
            </Button>
        );
    };

    handleCopy = item => {
        const copyData = {
            id: item.code,
            name: `Copy-${item.name}`
        };
        this.setState({ copyModalData: copyData });
        this.setState({ showModal: true });
    };

    renderCopyTemplate = item => {
        return (
            <Button automation-id='at-copy-icon' className={cx('iconAlign')} onClick={() => this.handleCopy(item)}>
                <Icon iconType='svg' width='25' height='24' viewBox='0 0 25 24' name='copy' />
            </Button>
        );
    };

    deleteModalOnClose = () => {
        this.setState({ deleteModalData: null });
    };

    handleRemove = row => {
        this.setState({ deleteModalData: row });
    };

    renderRemoveTemplate = row => {
        return (
            <Button
                automation-id='at-remove-icon'
                className={cx('removeButton')}
                onClick={() => this.handleRemove(row)}
            >
                <Icon iconType='svg' width='25' height='24' viewBox='0 0 25 24' name='remove' />
            </Button>
        );
    };

    onQuickLinkHandler = (e, id) => {
        e.preventDefault();
        const listItem = document.getElementById(id);
        if (listItem) {
            listItem.classList.toggle('OrderTemplate-expandedListItem');
        }
    };

    onPaginationHandler = (type, seletedItem) => {
        const { pagination } = this.props.orderTemplate;

        if (type === 'page') {
            this.props.actions.getOrderTemplateDetails({ pageNumber: seletedItem - 1, pageSize: pagination.pageSize });
        } else if (type === 'size') {
            this.props.actions.getOrderTemplateDetails({ pageNumber: 0, pageSize: seletedItem });
        }
    };

    submitExcel = (files) => {
        if (files) {
            const formData = new FormData();
            formData.append("file", files[0], files[0].name);
            this.props.actions.postExcel({ data: formData })
        }
    }

    templatePageHeader = () => {
        const {
            localization,
            commonLocalization,
            deviceType,
            orderTemplate: { pagination }
        } = this.props;
        const { showModal, copyModalData, excelOrderUpload } = this.state;
        const currentPage = pagination.currentPage || 0;

        return (
            <div className={cx('orderTempleft', 'clearfix')}>
                <h2>
                    <span className={cx('iconTop')}>
                        <Icon width='32' height='32' viewBox='0 0 32 32' name='search-order' />
                    </span>{' '}
                    <span automation-id='at-template-title-text' className={cx('TempTitle')}>
                        {localization[TEMP_L10N.TITLE]}
                    </span>
                </h2>

                {excelOrderUpload && deviceType.isDesktop && <div className={cx('orderControlsGroup', 'excelUploadControlGroup')}>
                    <div className={cx('totalOrder', 'col6', 'sm6')}>
                        <span automation-id='at-number-of-title'>{localization[GENERIC_L10N.DRAG_DROP_SUB_TITLE]}</span>
                    </div>
                    <div className={cx('orderTempright', 'col6', 'sm6')}>
                        <Button
                            automation-id='at-download-catalogue-button'
                            className={cx('create-new')}
                            type='button'
                            buttonType='Primary'
                            onClick={() => {
                                this.props.actions.getExcel()
                            }}
                        >
                            {localization[GENERIC_L10N.CATALOGUE]}
                            <Icon iconType='svg' width='15' height='15' viewBox="0 0 15 15" name='ic-download' />
                        </Button>
                    </div>
                </div>}

                {excelOrderUpload && deviceType.isDesktop && <div
                    className={cx('uploadOrderTemplateForm', 'excelUploadControlGroup')}
                    id="dropContainer"
                    onDragOver={e => e.preventDefault()}
                    onDrop={e => {
                        document.getElementById("filesInput").files = e.dataTransfer.files;
                        e.preventDefault();
                        this.submitExcel(e.dataTransfer.files);
                    }}
                >
                    <form id="uploadTemplateId" onSubmit={e => {
                    }}>
                        <Icon iconType='svg' width="40.203" height="34.46" viewBox="0 0 40.203 34.46" name='ic-upload' />
                        <div>
                            <span>{localization[GENERIC_L10N.DRAG_DROP_TITLE]}</span>
                            <div>
                                <label htmlFor="filesInput" className={cx('btn')}>
                                    {localization[GENERIC_L10N.BROWSE]}
                                </label>
                                <input
                                    id="filesInput"
                                    accept=".xls"
                                    type="file"
                                    className={cx('browseFileInput')}
                                    onChange={e => {
                                        this.submitExcel(e.currentTarget.files);
                                        e.target.value = null;
                                    }}
                                />
                            </div>
                        </div>
                        <label className={cx('supportLabel')}>
                            {localization[GENERIC_L10N.SUPPORT_FILES]}
                        </label>
                    </form>
                </div>}

                <div className={cx('orderControlsGroup')}>
                    <div className={cx('totalOrder', 'col6', 'sm6')}>
                        <span automation-id='at-number-of-title'>{localization[TEMP_L10N.TOTAL_TEMP]}</span>{' '}
                        <span className={cx('orderNumber')}>({pagination && pagination.totalResults})</span>
                    </div>
                    <div className={cx('orderTempright', 'col6', 'sm6')}>
                        <Button
                            automation-id='at-create-new-button'
                            className={cx('create-new')}
                            type='button'
                            buttonType='Primary'
                            onClick={this.handleCreateNewTemplate}
                        >
                            {commonLocalization[GENERIC_L10N.CREATE_NEW_BTN]}
                            <Icon iconType='svg' width='16' height='16' viewBox='0 0 16 16' name='add' />
                        </Button>
                        {showModal && (
                            <Modal
                                automationId='at-create-new-template-popup'
                                showModal={showModal}
                                onClose={this.OnClose}
                                modalTheme={cx('userThemeModal', 'orderuserThemeModal')}
                            >
                                <CreateNewTemplate
                                    copyTemplateData={copyModalData}
                                    onCancel={this.OnClose}
                                    pageNumber={`${currentPage}`}
                                    pageSize={`${pagination.pageSize}`}
                                />
                            </Modal>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { orderTemplate, localization, commonLocalization, templateUploadError } = this.props;
        const { modalData, deleteModalData, excelOrderUpload } = this.state;
        const { carts, pagination = {} } = orderTemplate;
        const currentPage = pagination.currentPage || 0;

        return (
            <Layout showDashboardHeader showOutlet pageId={PAGE_ID.TEMPLATE_LIST}>
                <div>
                    <BreadCrumbs breadcrumbValues={orderTemplate.breadcrumbs} />
                    {!isEmpty(orderTemplate) && !isEmpty(localization) && (
                        <div automation-id='at-order-template-list' className={cx('orderTemp', 'clearfix')}>
                            {this.templatePageHeader()}

                            <div className={cx('clearfix')}>
                                {pagination && pagination.totalPages > 0 && (
                                    <div className={cx('toppaginations', 'hiddenSm')}>
                                        <Pagination
                                            automation-id='at-page-number-dropdown'
                                            currentPage={currentPage + 1}
                                            totalPage={pagination.totalPages}
                                            onChange={e => this.onPaginationHandler('page', e)}
                                            itemOnChange={e => this.onPaginationHandler('size', e)}
                                            currentItems={pagination.pageSize}
                                            totalItemsPage={pagination.totalResults}
                                            renderType='List'
                                            pageSize={pagination.pageSize}
                                            itemsOptions={pagination.itemsPerPage}
                                            themeSelectBox={cx('themeSelectBox')}
                                            arrowTheme={cx('arrowTheme', 'down')}
                                            paginationSelect={cx('paginationSelect')}
                                            customListPage={cx('customListPage')}
                                            pageText={commonLocalization[GENERIC_L10N.PAGE]}
                                            itemsText={commonLocalization[GENERIC_L10N.ITEMS]}
                                            ofText={localization[PAGINATION_L10N.OF]}
                                        />
                                    </div>
                                )}
                            </div>
                            <div automation-id='at-list-of-template' className={cx('orderTempList', 'clearfix')}>
                                <ul>
                                    <li className={cx('orderlistheader', 'hiddenSm')}>
                                        <div className={cx('th-data', 'col3')}>{localization[TEMP_L10N.NAME]}</div>
                                        <div className={cx('th-data', 'col1', 'md2')}>
                                            {localization[TEMP_L10N.DATE_SAVED]}
                                        </div>
                                        <div className={cx('th-data', 'col2', 'qcenter')}>
                                            {localization[TEMP_L10N.QTY]}
                                        </div>
                                        <div className={cx('th-data', 'col3', 'md2')}>
                                            {localization[TEMP_L10N.DESC]}
                                        </div>
                                        <div className={cx('th-data', 'col3')}>&nbsp;</div>
                                    </li>
                                    {pagination && pagination.totalResults > 0 ? (
                                        carts &&
                                        carts.map((row, i) => {
                                            return (
                                                <li
                                                    id={`temp-${row.code}`}
                                                    key={i.toString()}
                                                    className={cx('orderlistItem', 'clearfix')}
                                                >
                                                    <div className={cx('td-data', 'col3', 'sm12')}>
                                                        <div className={cx('sm7')}>
                                                            <Link
                                                                to={`${URL_PATHS.ORDER_TEMPLATE_DETAILS}/${row.code}`}
                                                                automation-id='at-template-name'
                                                                className={cx('tempName', 'font-bold')}
                                                            >
                                                                {row.name}
                                                            </Link>
                                                        </div>

                                                        <div
                                                            className={cx(
                                                                'listItemhead',
                                                                'hiddenLg',
                                                                'visibleSm',
                                                                'quickView'
                                                            )}
                                                        >
                                                            <Button
                                                                automation-id='at-template-quickview'
                                                                type='linkbutton'
                                                                buttonType='Ordinary'
                                                                className={cx('quickviewBtn')}
                                                                onClick={e =>
                                                                    this.onQuickLinkHandler(e, `temp-${row.code}`)
                                                                }
                                                            >
                                                                {localization[TEMP_L10N.QUICK_VIEW]}
                                                                <Icon
                                                                    className={cx('downArrow')}
                                                                    width='16'
                                                                    height='16'
                                                                    viewBox='0 0 16 16'
                                                                    name='down-red'
                                                                />
                                                            </Button>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={cx(
                                                            'td-data',
                                                            'sm12',
                                                            'mobShow',
                                                            'OrderListShow',
                                                            'tempIDdata'
                                                        )}
                                                    >
                                                        <div className={cx('listItemhead', 'visibleSm', 'sm6')}>
                                                            {localization[TEMP_L10N.ID]}
                                                        </div>
                                                        <div
                                                            automation-id='at-template-date-saved'
                                                            className={cx(
                                                                'visibleSm',
                                                                'hiddenLg',
                                                                'sm6',
                                                                'mobData',
                                                                'text-right'
                                                            )}
                                                        >
                                                            {row.code}
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={cx(
                                                            'td-data',
                                                            'col1',
                                                            'md2',
                                                            'sm12',
                                                            'greyBG',
                                                            'OrderListShow'
                                                        )}
                                                    >
                                                        <div
                                                            className={cx(
                                                                'listItemhead',
                                                                'hiddenLg',
                                                                'hiddenMd',
                                                                'hiddenXl',
                                                                'visibleSm',
                                                                'sm6'
                                                            )}
                                                        >
                                                            {localization[TEMP_L10N.DATE_SAVED]}
                                                        </div>
                                                        <div
                                                            automation-id='at-template-date-saved'
                                                            className={cx('sm6', 'mobData')}
                                                        >
                                                            {row.formattedSaveTime}
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={cx(
                                                            'td-data',
                                                            'col2',
                                                            'sm12',
                                                            'qcenter',
                                                            'greyBG',
                                                            'OrderListShow'
                                                        )}
                                                    >
                                                        <div
                                                            className={cx(
                                                                'listItemhead',
                                                                'hiddenLg',
                                                                'hiddenMd',
                                                                'hiddenXl',
                                                                'visibleSm',
                                                                'sm6'
                                                            )}
                                                        >
                                                            {localization[TEMP_L10N.QTY]}
                                                        </div>
                                                        <div
                                                            automation-id='at-item-qty'
                                                            className={cx('sm6', 'mobData')}
                                                        >
                                                            {row.totalItems}
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={cx(
                                                            'td-data',
                                                            'col3',
                                                            'md2',
                                                            'sm12',
                                                            'OrderListShow',
                                                            'bdrbtmnone'
                                                        )}
                                                    >
                                                        <div
                                                            className={cx(
                                                                'listItemhead',
                                                                'hiddenLg',
                                                                'hiddenXl',
                                                                'visibleSm',
                                                                'sm6'
                                                            )}
                                                        >
                                                            {localization[TEMP_L10N.DESC]}
                                                        </div>
                                                        <div
                                                            automation-id='at-item-description'
                                                            className={cx('sm6', 'orderDescp')}
                                                        >
                                                            {row.description}
                                                        </div>
                                                    </div>

                                                    <div className={cx('td-data', 'actions-btn', 'col3', 'hiddenSm')}>
                                                        <div className={cx('actionBtn')}>
                                                            {this.renderRestoreTemplate(row)}
                                                        </div>
                                                        <span className={cx('actionIcon')}>
                                                            {this.renderCopyTemplate(row)}
                                                        </span>
                                                        <span className={cx('dotIcon')}>
                                                            <Icon
                                                                iconType='svg'
                                                                width='25'
                                                                height='24'
                                                                viewBox='0 0 25 24'
                                                                name='horizontal'
                                                            />
                                                        </span>
                                                        <span className={cx('actionIcon')}>
                                                            {this.renderRemoveTemplate(row)}
                                                        </span>
                                                    </div>
                                                </li>
                                            );
                                        })
                                    ) : (
                                        <li
                                            automation-id='at-no-orders-found-text'
                                            className={cx('orderlistItem', 'noResults', 'clearfix')}
                                        >
                                            <Icon
                                                iconType='svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                name='no-results'
                                            />{' '}
                                            {commonLocalization[GENERIC_L10N.NO_RESULTS_MSG]}
                                        </li>
                                    )}
                                </ul>
                            </div>
                            {templateUploadError && (
                                <Modal
                                    automationId='at-restore-template-popup'
                                    showModal
                                    onClose={this.props.actions.resetExcelErrors}
                                    modalTheme={cx('userThemeModal', 'orderuserThemeModal')}
                                >
                                    <OrderTemplateModal content={templateUploadError} onClose={this.props.actions.resetExcelErrors} />
                                </Modal>
                            )}
                            {modalData && (
                                <Modal
                                    automationId='at-restore-template-popup'
                                    showModal={Boolean(modalData)}
                                    onClose={this.modalOnClose}
                                    modalTheme={cx('userThemeModal', 'orderuserThemeModal')}
                                >
                                    <RestoreModalContent
                                        row={modalData}
                                        onCancel={this.modalOnClose}
                                        pageNumber={`${currentPage}`}
                                        pageSize={`${pagination.pageSize}`}
                                    />
                                </Modal>
                            )}
                            {deleteModalData && (
                                <Modal
                                    automationId='at-delete-template-popup'
                                    showModal={Boolean(deleteModalData)}
                                    onClose={this.deleteModalOnClose}
                                    modalTheme={cx('userThemeModal', 'orderuserThemeModal')}
                                >
                                    <DeleteTemplateModalContent
                                        row={deleteModalData}
                                        onCancel={this.deleteModalOnClose}
                                        pageNumber={`${currentPage}`}
                                        pageSize={`${pagination.pageSize}`}
                                    />
                                </Modal>
                            )}
                        </div>
                    )}
                    {pagination && !isEmpty(localization) && pagination.totalPages > 0 && (
                        <div className={cx('bottomcounts')}>
                            <div className={cx('pagger')}>
                                <Pagination
                                    id='paginationId'
                                    currentPage={currentPage + 1}
                                    totalPage={pagination.totalPages}
                                    onClick={e => this.onPaginationHandler('page', e)}
                                    pageSize={pagination.pageSize}
                                    itemsOptions={pagination.itemsPerPage}
                                    paginationSelect={cx('paginationSelect')}
                                    itemOnChange={e => this.onPaginationHandler('size', e)}
                                    pageText={commonLocalization[GENERIC_L10N.PAGE]}
                                    itemsText={commonLocalization[GENERIC_L10N.ITEMS]}
                                    ofText={localization[PAGINATION_L10N.OF]}
                                    arrowTheme={cx('arrowTheme')}
                                />
                            </div>
                            <span className={cx('hiddenSm', 'Items')}>
                                {pagination.totalResults} {localization[TEMP_L10N.NO_OF_ORDERS_TEXT]}
                            </span>
                        </div>
                    )}
                </div>
            </Layout>
        );
    }
}

OrderTemplate.propTypes = {
    getOrderTemplateDetails: PropTypes.func,
    orderTemplate: PropTypes.objectOf(PropTypes.any),
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

OrderTemplate.defaultProps = {
    getOrderTemplateDetails: undefined,
    orderTemplate: {},
    commonLocalization: {}
};

const mapStateToProps = state => {
    return {
        orderTemplate: _get(state, 'orderTemplate.templateList'),
        templateUploadError: _get(state, 'orderTemplate.templateUploadError'),
        localization: _get(state, 'pageContent.localization'),
        deviceType: _get(state, 'context.deviceType'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`, {})
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getOrderTemplateDetails,
            getPageContent,
            getExcel,
            postExcel,
            resetExcelErrors,
            getInvoicePDF
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderTemplate);
