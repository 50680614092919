import { put, takeLatest, call } from 'redux-saga/effects';
import _get from 'lodash/get';
import FactorySaga from './FactorySaga';
import InvoicesApi from '../interfaces/Invoices/InvoicesApi';
import { getErrorInfo } from '../common/Utils/Utils';
import { notifySuccess } from '../actions/NotificationAction';

import {
    GET_INVOICES_REQUEST,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_ERROR,
    CREATE_CLAIM_REQUEST,
    CREATE_CLAIM_SUCCESS,
    CREATE_CLAIM_ERROR,
    GET_FILTER_INVOICES_SUCCESS,
    GET_FILTER_INVOICES_ERROR,
    GET_FILTER_INVOICES_REQUEST,
    SUMBIT_CLAIM_REQUEST,
    GET_INVOICE_PDF_REQUEST,
    GET_INVOICE_PDF_SUCCESS,
    GET_INVOICE_PDF_ERROR,
    GET_INVOICE_ZIP_SUCCESS
} from '../actionTypes/InvoicesActionTypes';

export function* getInvoicesSaga(action) {
    try {
        const result = yield call(FactorySaga, InvoicesApi.getInvoices, action);

        if (result.isSuccess) {
            yield put({ type: GET_INVOICES_SUCCESS, data: _get(result.response, 'data') });
        } else {
            yield put({ type: GET_INVOICES_ERROR, data: { error: getErrorInfo(result) } });
        }
    } catch (err) {
        yield put({ type: GET_INVOICES_ERROR });
    }
}

export function* createClaimSaga(action) {
    try {
        const result = yield call(FactorySaga, InvoicesApi.createClaim, action);

        if (result.isSuccess) {
            yield put({
                type: CREATE_CLAIM_SUCCESS,
                data: _get(result.response, 'data'),
                isSuccess: _get(result, 'isSuccess', false)
            });
        }
    } catch (err) {
        yield put({ type: CREATE_CLAIM_ERROR });
    }
}

export function* filterInvoicesSaga(action) {
    try {
        const result = yield call(FactorySaga, InvoicesApi.filterInvoices, action);

        if (result.isSuccess) {
            yield put({ type: GET_FILTER_INVOICES_SUCCESS, data: _get(result.response, 'data') });
        } else {
            yield put({ type: GET_FILTER_INVOICES_ERROR, data: { error: getErrorInfo(result) } });
        }
    } catch (err) {
        yield put({ type: GET_FILTER_INVOICES_ERROR });
    }
}

export function* submitClaimSaga(action) {
    try {
        const result = yield call(FactorySaga, InvoicesApi.submitClaim, action);

        if (result.isSuccess) {
            yield call(getInvoicesSaga);
            yield put(notifySuccess(result.response.data));
        }
    } catch (err) {
        yield put({ type: GET_INVOICES_ERROR });
    }
}

export function* getInvoicePDFSaga(action) {
    try {
        const result = yield call(FactorySaga, InvoicesApi.getInvoicePDF, action);
        if (result.isSuccess) {
            const contentType = result.response.headers['content-type'];
            const xNumberPdfs = result.response.headers['x-number-pdfs'];
            if (contentType === 'application/zip') {
                yield put({
                    type: GET_INVOICE_ZIP_SUCCESS,
                    data: {
                        blob: window.URL.createObjectURL(result.response.data),
                        xNumberPdfs
                    }
                });
            } else {
                yield put({
                    type: GET_INVOICE_PDF_SUCCESS,
                    data: {
                        blob: window.URL.createObjectURL(result.response.data),
                        xNumberPdfs
                    }
                });
            }
        } else {
            yield put({ type: GET_INVOICE_PDF_ERROR, data: { error: getErrorInfo(result) } });
        }
    } catch (err) {
        console.error(err);
        yield put({ type: GET_INVOICE_PDF_ERROR });
    }
}

export default function* watchInvoicesDetail() {
    yield takeLatest(GET_INVOICES_REQUEST, getInvoicesSaga);
    yield takeLatest(CREATE_CLAIM_REQUEST, createClaimSaga);
    yield takeLatest(SUMBIT_CLAIM_REQUEST, submitClaimSaga);
    yield takeLatest(GET_FILTER_INVOICES_REQUEST, filterInvoicesSaga);
    yield takeLatest(GET_INVOICE_PDF_REQUEST, getInvoicePDFSaga);
}
