import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import classNames from 'classnames/bind';
import Layout from '../../../components/site/Layout/Layout';
import { getOrderDetailData, resetOrderDetails } from '../../../actions/OrderDetailsAction';
import OrderDetailComponent from '../../../components/site/OrderDetail/OrderDetailComponent';
import Reorder from '../../../components/site/Reorder/Reorder';
import { getPageContent } from '../../../actions/PageContentAction';
import { PAGE_ID, ORDERS_L10N } from '../../../common/Constants';
import * as styles from './OrderDetail.css';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import Icon from '../../../components/core/Icon/Icon';
import { getCountry } from '../../../common/UserManager/UserManager';
import ReactToPrint from 'react-to-print';

const cx = classNames.bind(styles);

export class OrderDetail extends Component {
    componentPrintRef = null;
    componentDidMount() {
        // this.props.actions.getOrderDetailData(this.props.params.orderId);
        this.props.actions.getPageContent({
            pageId: PAGE_ID.ORDER_DETAILS,
            orderId: this.props.params.orderId
        });
    }

    componentWillUnmount() {
        this.props.actions.resetOrderDetails();
    }

    reactToPrintContent = () => {
        return this.componentPrintRef;
    };

    setPrintComponentRef = ref => {
        this.componentPrintRef = ref;
    };

    orderStatusTrack = (localization, orderData) => {
        const listImgCreate = '../../../assets/svg/created.svg';
        let listImgInprogress = '../../../assets/svg/in-progress.svg';
        let ListImgShiped = '../../../assets/svg/shipped.svg';
        let ListImgDelivery = '../../../assets/svg/delivered.svg';
        const undone = '../../../assets/svg/undone.svg';
        const orderStatus = orderData && orderData.status;
        let orderStatusOpen;
        let orderStatusInProgress;
        let orderStatusShipped;
        let orderStatusCancelled;
        const orderStatusList = ORDERS_L10N.ORDER_STATUS_LIST;
        if (orderStatusList && orderStatusList.length > 0) {
            const statusList = orderStatusList.toString().split(',');
            const orderStatusDelivered = orderStatus === statusList[3];
            switch (orderStatus) {
                case statusList[0]:
                    orderStatusOpen = true;
                    listImgInprogress = undone;
                    ListImgShiped = undone;
                    ListImgDelivery = undone;
                    break;
                case statusList[1]:
                    orderStatusInProgress = true;
                    ListImgShiped = undone;
                    ListImgDelivery = undone;
                    break;
                case statusList[2]:
                    orderStatusShipped = true;
                    ListImgDelivery = undone;
                    break;
                case statusList[4]:
                    orderStatusCancelled = true;
                    listImgInprogress = '../../../assets/svg/cancelled.svg';
                    ListImgShiped = '';
                    ListImgDelivery = '';
                    break;
                default:
            }
            return (
                <div
                    className={cx(
                        'OrderStatus',
                        'col12',
                        orderStatusCancelled && 'orderStatusDelivery',
                        orderStatusDelivered && 'deliveredStatus',
                        orderStatusOpen && 'OpenStatus',
                        orderStatusInProgress && 'InprogressStatus',
                        orderStatusShipped && 'shippedStatus'
                    )}
                    automation-id='at-tracking-information'
                >
                    <div className={cx('col2', 'md12', 'StatusTitile', 'hiddenSm')}>
                        <h5>{localization[ORDERS_L10N.ORDER_STATUS_TITLE]}</h5>
                    </div>
                    <div className={cx('orderProgress', 'col10', 'md12', 'sm12')}>
                        <div className={cx('orderStatusBar')}>
                            <div className={cx('dottedline')}>
                                <div className={cx('statusSteps', 'statusStep1')}>
                                    {this.statusSteps(
                                        'statusTxt1',
                                        listImgCreate,
                                        localization[ORDERS_L10N.ORDER_CREATED_STATUS],
                                        orderData.formattedCreatedDate,
                                        'at-created-stage'
                                    )}
                                </div>
                                <div className={cx('statusSteps', 'statusStep2')}>
                                    {this.statusSteps(
                                        'statusTxt2',
                                        listImgInprogress,
                                        orderStatusCancelled
                                            ? localization[ORDERS_L10N.ORDER_CANCELED_STATUS]
                                            : localization[ORDERS_L10N.ORDER_INPROGRESS_STATUS],
                                        orderData.trackingDeliveryDate,
                                        orderStatusCancelled ? 'at-cancelled-stage' : 'at-in-progress-stage'
                                    )}
                                </div>
                                <div className={cx('statusSteps', 'statusStep3')}>
                                    {this.statusSteps(
                                        'statusTxt3',
                                        ListImgShiped,
                                        localization[ORDERS_L10N.ORDER_SHIPPED_STATUS],
                                        orderData.shipmentStarted,
                                        'at-shipped-stage'
                                    )}

                                    {this.statusSteps(
                                        'statusTxt4',
                                        ListImgDelivery,
                                        localization[ORDERS_L10N.ORDER_DELIVERED_STATUS],
                                        orderData.shipmentEnd,
                                        'at-delivered-stage'
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    statusSteps = (statusTxtClass, image, status, date, automationId) => {
        return (
            <div className={cx('statusTxt', statusTxtClass)} automation-id={automationId}>
                <img src={image} alt={status} />
                <h3>
                    <span className={cx('statusleftTxt')}>{status}</span>
                    {date ? (
                        <span className={cx('statusrightTxt')}>{date}</span>
                    ) : (
                        <span className={cx('statusrightTxt')}>- - -</span>
                    )}
                </h3>
            </div>
        );
    };

    render() {
        const { orderDetailData, localization } = this.props;
        const { breadcrumbs, orderData, isReOrderable, showOrderStatusTracking = true } = orderDetailData;
        const country = getCountry();

        return (
            <Layout showDashboardHeader showOutlet pageId={PAGE_ID.ORDER_DETAILS}>
                <BreadCrumbs breadcrumbValues={breadcrumbs} />
                <div ref={this.setPrintComponentRef}>
                    {orderData && localization ? (
                        <div className={cx('orderstatuswraper')}>
                            <h4 automation-id='at-order-details-title-text' className={cx('clearfix', 'pageTitle')}>
                                <span className={cx('detailTitle')}>
                                    {' '}
                                    <Icon width='32px' height='32px' viewBox='0 0 32 32' name='order-history' />
                                    {localization[ORDERS_L10N.ORDER_DETAILS_TITLE]}
                                </span>
                                <div className={cx('orderPrint', 'hiddenMd', 'hiddenSm', 'pull-right')}>
                                    {country !== 'Belarus' && (
                                        <ReactToPrint
                                            content={this.reactToPrintContent}
                                            documentTitle='document'
                                            removeAfterPrint
                                            trigger={() => (
                                                <button
                                                    type='button'
                                                    aria-label='print'
                                                    automation-id='at-print-button'
                                                    className={cx('print-btn')}
                                                >
                                                    <Icon
                                                        iconType='svg'
                                                        width='25px'
                                                        height='24px'
                                                        viewBox='0 0 25 24'
                                                        name='print'
                                                    />
                                                </button>
                                            )}
                                        />
                                    )}
                                </div>
                            </h4>
                            <div
                                className={cx('col12', 'sm12', 'ordernoandreorder', 'hiddenLg', 'hiddenMd', 'hiddenXl')}
                            >
                                <div className={cx('orderno-left', 'md6')}>
                                    <h5 automation-id='at-order-number-text' className={cx('tableTitle')}>
                                        {localization[ORDERS_L10N.ORDER_NUMER]}
                                    </h5>
                                    <span automation-id='at-order-number-link' className={cx('orderId', 'textBrand')}>
                                        {orderData.code}
                                    </span>
                                </div>
                                <div className={cx('orderno-right', 'md3')}>
                                    {isReOrderable && (
                                        <Reorder
                                            orderId={orderData.code}
                                            customReorderStyle={cx('reOrderBtn')}
                                            buttonValue={localization[ORDERS_L10N.REORDER_BTN]}
                                            automationId='at-reorder-button'
                                            modalLocalization={{
                                                cancelBtn: localization[ORDERS_L10N.REORDER_POPUP_CANCEL_BUTTON],
                                                checkoutBtn: localization[ORDERS_L10N.REORDER_POPUP_CHECKOUT_BUTTON],
                                                header: localization[ORDERS_L10N.REORDER_POPUP_HEADER_TEXT],
                                                subtext: localization[ORDERS_L10N.REORDER_POPUP_SUBTEXT],
                                                warning: localization[ORDERS_L10N.REORDER_POPUP_SUBTEXT_WARNING]
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                            {showOrderStatusTracking && this.orderStatusTrack(localization, orderData)}
                        </div>
                    ) : (
                        ''
                    )}
                    <OrderDetailComponent />
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        orderDetailData: state.orderDetailData,
        localization: _get(state, 'pageContent.localization'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`, {})
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getOrderDetailData,
            getPageContent,
            resetOrderDetails
        },
        dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
