/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { LOGIN_UID } from '../../../common/Constants';
import NavLink from '../../core/NavLink/NavLink';
import { isShowCookieConsent, setCookieConsentStatus, getSiteId } from '../../../common/UserManager/UserManager';
import SmartEdit from '../SmartEdit/SmartEdit';

import * as styles from './Footer.css';
const cx = classNames.bind(styles);

const FooterLogin = props => {
    const loginFooterComp = useSelector(state => state.loginPage[LOGIN_UID.FOOTER]);
    const [isShowCookiePopUp, setIsShowCookiePopUp] = useState(isShowCookieConsent());

    const { content = {} } = props.footerComponent || loginFooterComp;
    const isNigeria = getSiteId() === "ng";
    const footerFromProps = props?.footerComponent || {};
    let privacyCompFromProps;
    let ourCompanyCompFromProps;
    let termsAndConditionCompFromProps;
    if (!_isEmpty(footerFromProps)) {
        privacyCompFromProps = {...footerFromProps[LOGIN_UID.PRIVACY]?.childComponents[0], ...footerFromProps[LOGIN_UID.PRIVACY]};
        ourCompanyCompFromProps = {...footerFromProps[LOGIN_UID.OUR_COMPANY]?.childComponents[0], ...footerFromProps[LOGIN_UID.OUR_COMPANY]};
        termsAndConditionCompFromProps = {...footerFromProps[LOGIN_UID.TERMS]?.childComponents[0], ...footerFromProps[LOGIN_UID.TERMS]};
    }

    const footerComp = footerFromProps[LOGIN_UID.FOOTER] || loginFooterComp[LOGIN_UID.FOOTER];
    const imprintComp = footerFromProps[LOGIN_UID.IMPRINT] || loginFooterComp[LOGIN_UID.IMPRINT] || {};
    const ourCompanyComp = ourCompanyCompFromProps || loginFooterComp[LOGIN_UID.OUR_COMPANY] || {};
    const contactUsComp = footerFromProps[LOGIN_UID.CONTACT_US] || loginFooterComp[LOGIN_UID.CONTACT_US] || {};
    const privacyComp = privacyCompFromProps || loginFooterComp[LOGIN_UID.PRIVACY] || {};
    const termsAndConditionComp = termsAndConditionCompFromProps || loginFooterComp[LOGIN_UID.TERMS] || {};
    const copyRight = footerFromProps[LOGIN_UID.COPY_RIGHT] || loginFooterComp[LOGIN_UID.COPY_RIGHT];
    const cookieConfirmText = content[LOGIN_UID.CONTENT.COOKIE_CONFIRM];
    const okayBtn = content[LOGIN_UID.CONTENT.OKAY_BTN];

    const hideCookieConsent = () => {
        setCookieConsentStatus(true);
        setIsShowCookiePopUp(false);
    };

    return (
        <div className={cx('footerContainer', 'loginFooter')}>
            <SmartEdit data={footerComp}>
            <footer className={cx('footerCopyright')}>
                <div className={cx('footerTopLinks')}>
                    <div className={cx('footerLinksLogin', 'sm12')}>
                        <ul>
                            <li>
                                <NavLink linkUrl={imprintComp.url} automationId='at-imprint-link'>
                                    {imprintComp.name || ''}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink linkUrl={ourCompanyComp.url} automationId='at-our-company-link'>
                                    {ourCompanyComp.name || ''}
                                </NavLink>
                            </li>
                            {isNigeria && (
                                <li>
                                    <NavLink
                                        target="_blank"
                                        linkUrl={termsAndConditionComp?.url || ''}
                                        automationId='at-terms-and-conditions-link'>
                                        {termsAndConditionComp?.name}
                                    </NavLink>
                                </li>
                                )
                            }
                            <li>
                                <NavLink
                                    linkUrl={contactUsComp.url}
                                    onClick={e => props.handlePopup(e, contactUsComp)}
                                    automationId='at-contact-us-link'
                                >
                                    {contactUsComp.name || ''}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className={cx('rightLink', 'sm12')}>
                        <ul>
                            <li>
                                <NavLink
                                    target="_blank"
                                    linkUrl={privacyComp?.url || ''}
                                    automationId='at-terms-and-conditions-link'>
                                    {privacyComp?.name}
                                </NavLink>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <p className={cx('copyTxt', 'col12')}>{copyRight}</p>
                </footer>
            </SmartEdit>

            {isShowCookiePopUp && privacyComp.urlLink && (
                <div className={cx('cookieConsentFooter')} automation-id='at-cookie-policy-popup'>
                    <p className={cx('cookieinfotext', 'col7', 'sm12')} automation-id='at-cookie-policy-text'>
                        {cookieConfirmText}
                    </p>
                    <p className={cx('cookieinfolink', 'col5', 'sm12')}>
                        <NavLink
                            target="_blank"
                            linkUrl={privacyComp?.url || ''}
                            theme={cx('cookielink')}
                            automationId='at-terms-and-conditions-link'>
                            {privacyComp?.name}
                        </NavLink>
                        <button
                            type='button'
                            className={cx('okaybtn', 'textBrand')}
                            title='Okay'
                            onClick={() => hideCookieConsent()}
                            automation-id='at-ok-button'
                        >
                            {okayBtn}
                        </button>
                    </p>
                </div>
            )}
        </div>
    );
};

export default FooterLogin;