/* eslint-disable no-restricted-syntax */
import { browserHistory } from 'react-router';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { useEffect, useRef } from 'react';
import Storage from '../Storage/Storage';
import { LOGIN_INFO, URL_PATHS, PLP_FILTERS, IMAGE_COMING_SOON, SMART_EDIT } from '../Constants';
import {
    isReturnEmpties,
    isUseMarketingMaterials,
    logout,
    getLocalization,
    getCountryLocaleCode
} from '../UserManager/UserManager';
import { getPageContent } from '../../actions/PageContentAction';
import * as FullStory from '@fullstory/browser';

/**
 * To detect the idle time of the user activity for 30 mins.
 */
export const detectIdleTimeOut = () => {
    let time = 0;

    function resetTimer() {
        clearTimeout(time);
        time = setTimeout(() => {
            // Remove the current session.
            logout();
            clearTimeout(time);
        }, 1800000);
    }

    document.onmousemove = resetTimer;
    document.onmousedown = resetTimer; // touchscreen presses
    document.ontouchstart = resetTimer;
    document.onclick = resetTimer; // touchpad clicks
    document.onscroll = resetTimer; // scrolling with arrow keys
    document.onkeypress = resetTimer;
};

export const getSelectOptionText = (event = {}) => {
    const { target: { value, selectedIndex } = {} } = event;
    return _get(event, `target[${selectedIndex}].text`, value);
};

export const emailRegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,5}$/;

export const phoneNumber = /^[0-9]*$/;

export function getErrorInfo(result) {
    const errors = _get(result, 'response.data.errors');

    if (errors) {
        return (Array.isArray(errors) ? errors[0] : errors) || {};
    }
    return {};
}

export function getErrorInfoById(result, id) {
    const errors = _get(result, 'response.data.errors');
    return _find(errors, ['type', id]);
}

export const redirectTo = redirectUrl => {
    browserHistory.push(redirectUrl);
};

export function trimString(key, match = /^LF_/) {
    return (key || '').replace(match, '');
}

export function getDecimal(value) {
    return typeof value === 'number' ? value.toFixed(2) : value;
}

export const addDays = (date, days) => {
    const getDate = new Date(Number(date));
    getDate.setDate(date.getDate() + days);
    return getDate;
};

export const navigateFromLogin = () => {
    const targetUrl = Storage.getData(LOGIN_INFO.REDIRECT_FROM) || URL_PATHS.HOME;
    Storage.removeData(LOGIN_INFO.REDIRECT_FROM);
    redirectTo(targetUrl);
};

const facetUriUpdate = str => encodeURIComponent(str.toLowerCase()).replace('%20', '+');

export const getUpdatedURL = (plpType, queryParam, appliedFacets = {}) => {
    const uri = `${URL_PATHS.PLP}/${plpType}`;

    const appliedClonedFacets = { ...appliedFacets.data };
    if (appliedClonedFacets[PLP_FILTERS.PAGE] !== undefined) {
        delete appliedClonedFacets[PLP_FILTERS.PAGE];
        appliedClonedFacets[PLP_FILTERS.PAGE] = appliedFacets.data[PLP_FILTERS.PAGE];
    }
    const fType = Object.keys(appliedClonedFacets).join();
    Object.keys(appliedClonedFacets).forEach(item => {
        appliedClonedFacets[item] = appliedClonedFacets[item].join('|');
    });
    const encodedFType = fType
        .split(',')
        .map(facetType => encodeURIComponent(facetType))
        .join();
    const encodedFValue = Object.values(appliedClonedFacets)
        .map(facetValue => encodeURIComponent(facetValue))
        .join();

    const updatedStr =
        encodedFType === '' && encodedFValue === '' ? `${uri}` : `${uri}/${encodedFType}/${encodedFValue}`;
    redirectTo(`${updatedStr}${queryParam}`);
};

export const productListGetURL = (appliedFacets = {}, freeTextSearch = '', isEmpties, isMarketing) => {
    let uri = '';
    const filterTypeArray = [];
    const fitlerValuesArray = [];
    let filters = '';
    const appliedClonedFacets = { ...appliedFacets.data };
    let isPromo;

    for (const [key, value] of Object.entries(appliedClonedFacets)) {
        if (key === PLP_FILTERS.FAVORITE) {
            uri += `&isFavorite=true`;
        } else if (key === PLP_FILTERS.IN_STOCK) {
            uri += `&isInStock=true`;
        } else if (key === PLP_FILTERS.PROMOTION) {
            isPromo = true;
            const newstr = value.map(val => encodeURIComponent(val)).join('|');
            fitlerValuesArray.push(newstr);
            filterTypeArray.push(key);
        } else if (key === PLP_FILTERS.RECOMMENDATIONS) {
            uri += `&bdaa=true`;
        } else if (key === PLP_FILTERS.PAGE) {
            const selectedPage = parseInt(value[0], 10) <= 1 ? 0 : parseInt(value[0], 10) - 1;
            if (selectedPage) {
                uri += `&currentPage=${selectedPage}`;
            }
        } else if (key === PLP_FILTERS.PAGE_ITEM_SIZE) {
            uri += `&pageSize=${value[0]}`;
        } else if (key === PLP_FILTERS.SHOW_PRICE) {
            uri += `&${PLP_FILTERS.SHOW_PRICE}=${value[0]}`;
        } else if (key === PLP_FILTERS.SAVED_FILTERS) {
            uri += `&saveUpdateSavedFilter=true`;
        } else if (key === PLP_FILTERS.MAKE_DEFAULT_SAVED_FILTERS) {
            uri += `&isSavedFilterDefault=true`;
        } else if (key === PLP_FILTERS.REMOVE_SAVED_FILTERS) {
            uri += '&removeSavedFilter=true';
        } else {
            const newstr = value.map(val => encodeURIComponent(val)).join('|');
            fitlerValuesArray.push(newstr);
            filterTypeArray.push(key);
        }
    }
    if (filterTypeArray.length > 0) {
        if (isPromo) {
            const promoDataIndex = filterTypeArray.indexOf('promotion');
            filters += `&promoFacets=${encodeURIComponent(fitlerValuesArray[promoDataIndex])}`;
            filterTypeArray.splice(promoDataIndex, 1);
            fitlerValuesArray.splice(promoDataIndex, 1);
        }

        if (filterTypeArray.length > 0) {
            filters += `&filterTypes=${filterTypeArray.join()}&filterValues=${encodeURIComponent(
                fitlerValuesArray.join()
            )}`;
        }
    }
    if (freeTextSearch !== '') {
        if (isEmpties) {
            freeTextSearch = `&freeTextQuery=${facetUriUpdate(freeTextSearch)}&useEmpties=true`;
        } else if (isMarketing) {
            freeTextSearch = `&freeTextQuery=${facetUriUpdate(freeTextSearch)}&useMarketingMaterials=true`;
        } else {
            freeTextSearch = `&freeTextQuery=${facetUriUpdate(freeTextSearch)}`;
        }
    }

    if (isEmpties) {
        uri += '&useEmpties=true';
    }

    if (isMarketing) {
        uri += '&useMarketingMaterials=true';
    }
    uri = `${uri}${filters}${freeTextSearch}`;

    return uri.split('&').sort().join('&');
};

// Parse the filter string sent back from the savedFilters variable in the /products/search? API call
export const parseSavedFilters = filterString => {
    const pairs = filterString.split('&');
    const result = {};

    pairs.forEach(pair => {
        const [key, value] = pair.split('=');

        if (key === 'filterTypes') {
            const types = value.split(',');
            const values = pairs
                .find(p => p.startsWith('filterValues='))
                .split('=')[1]
                .split(',');

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < types.length; i++) {
                if (values[i]) {
                    if (['minPriceFilterValue', 'maxPriceFilterValue'].includes(types[i])) {
                        result[types[i]] = [values[i]];
                    } else if (types[i] === 'isPricePerPieceFilterActive') {
                        result[types[i]] = [values[i]];
                    } else {
                        result[types[i]] = values[i].split('|').map(v => decodeURIComponent(v));
                    }
                }
            }
        } else if (key !== 'filterValues' && key !== 'promoFacets' && value) {
            let modifiedKey = key;

            // Transform specific keys because that's how they are used on the ProductList page.
            if (key === 'isFavorite') modifiedKey = 'favorite';
            if (key === 'isInStock') modifiedKey = 'inStock';

            if (['favorite', 'inStock', 'bdaa'].includes(modifiedKey) && (value === 'false' || value === '')) {
                // Skip falsey values for specific keys
                return;
            }

            result[modifiedKey] = [decodeURIComponent(value)];
        }
    });

    // Handle promoFacets key
    const promoFacets = pairs.find(pair => pair.startsWith('promoFacets='));
    if (promoFacets) {
        const [, value] = promoFacets.split('=');
        if (value) {
            result.promotion = decodeURIComponent(value).split('|');
        }
    }

    return result;
};

export const getElement = selector => {
    const element = document.querySelector(selector);
    return (element && element.getBoundingClientRect()) || {};
};

export const getSysDate = date => {
    const currentDate = new Date();

    try {
        const sysDate = (date || '').split('/');

        if (sysDate.length === 3) {
            return new Date(sysDate[2], sysDate[1] - 1, sysDate[0]);
        }
    } catch (ex) {
        return currentDate;
    }
    return currentDate;
};

export function getProductCode(url, codeShort) {
    const [productId] = (url || '').split('/').slice(-1) || codeShort;
    return productId;
}

export function goToPDP(url, codeShort) {
    return redirectTo(`${URL_PATHS.PDP}/${getProductCode(url, codeShort)}`);
}

export function getImageByFormat(images, format = 'thumbnail') {
    if (!images || !images.length) {
        return { url: IMAGE_COMING_SOON, defaultImage: true };
    }
    const image = images.find(item => item.format === format);
    return image || images[0];
}

export function isReturnEmptiesPage() {
    const pathName = window.location.pathname;
    if (pathName.includes(`${URL_PATHS.PLP_ORDER}`)) {
        return false;
    }
    if (pathName.includes(`${URL_PATHS.PLP_SEARCH}`)) {
        const searchParams = new URLSearchParams(window.location.search);
        const searchTerm = searchParams.get('re');
        return searchTerm;
    }
    if (pathName.includes(`${URL_PATHS.PLP_RETURN}`)) {
        return true;
    }
    if (isReturnEmpties()) {
        return true;
    }
    return false;
}

export function isMarketingMaterialsPage() {
    const pathName = window.location.pathname;
    if (pathName.includes(`${URL_PATHS.PLP_ORDER}`)) {
        return false;
    }
    if (pathName.includes(`${URL_PATHS.PLP_SEARCH}`)) {
        const searchParams = new URLSearchParams(window.location.search);
        const searchTerm = searchParams.get('ma');
        return searchTerm;
    }
    if (pathName.includes(`${URL_PATHS.PLP_ZPOP}`)) {
        return true;
    }
    if (isUseMarketingMaterials()) {
        return true;
    }
    return false;
}

/**
 * This is to identify whether preserved order list is the initial state of order history page.
 *
 * @param array orderList
 */
export const isValidOrderList = orderList => {
    let isValidOrderData = true;

    if (!_isEmpty(orderList)) {
        const { currentPeriod, currentProcessType, currentStatusType, pagination, orderId } = orderList;

        if (
            currentPeriod !== '30' ||
            currentProcessType !== 'All' ||
            (currentStatusType !== '' && currentStatusType !== 'All') ||
            (pagination || {}).currentPage !== 0 ||
            orderId
        ) {
            isValidOrderData = false;
        }
    } else {
        isValidOrderData = false;
    }
    return isValidOrderData;
};

/**
 * Replaces the body classes
 *
 */
const replaceBodyClasses = data => {
    const newBodyClasses = [];

    const bodyClasses = document.body.classList;
    for (const bodyClass of bodyClasses) {
        document.body.classList.remove(bodyClass);
        if (!bodyClass.includes('smartedit')) {
            newBodyClasses.push(bodyClass);
        }
    }

    if (
        data.pageContent.pageUid &&
        data.pageContent.pageUuid &&
        data.pageContent.catalogID &&
        data.pageContent.catalogVersion
    ) {
        newBodyClasses.push(`smartedit-page-uid-${data.pageContent.pageUid}`);
        newBodyClasses.push(`smartedit-page-uuid-${data.pageContent.pageUuid}`);
        newBodyClasses.push(
            `smartedit-catalog-version-uuid-${data.pageContent.catalogID}/${data.pageContent.catalogVersion}`
        );
    }

    document.body.classList.add(...newBodyClasses);
};

/**
 * Load SmartEdit webApplicationInjector and adds body classes
 *
 */
export const initializeSmartEdit = Store => {
    const state = Store.getState();
    if (window !== window.parent) {
        const cchCommerceUrl = _get(state, 'context.environment.apiHosts.cchcommercesmartedit');
        const script = document.createElement('script');
        script.src = '../../assets/webApplicationInjector.js';
        script.dataset.smarteditAllowOrigin = cchCommerceUrl;
        script.async = true;
        document.body.appendChild(script);

        window.smartedit = window.smartedit || {};
        window.smartedit.reprocessPage = () => {};

        Store.subscribe(() => {
            const state = Store.getState();

            replaceBodyClasses(state);

            window.smartedit.renderComponent = (componentId, componentType, parentId) => {
                Store.dispatch(getPageContent({ pageId: state.pageContent.pageId }));
            };
        });
    }
};

/**
 * Loads the SAP CDC Web SDK (gigya) and adds it to the head of the document
 *
 */
export const loadCDCSDK = state => {
    const loaded = document.getElementById('sap-cdc');
    if (loaded) {
        return Promise.resolve();
    }

    const apiKey = _get(state, 'context.environment.sapCDCApiKey');
    const language = getCountryLocaleCode();

    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.id = 'sap-cdc';
        script.src = `https://cdns.eu1.gigya.com/js/gigya.js?apikey=${apiKey}&lang=${language}`;
        script.async = true;
        script.onload = () => {
            if (window.gigya) {
                resolve();
            } else {
                reject(new Error('Failed to load Gigya SDK.'));
            }
        };
        document.head.appendChild(script);
    });
};

export const loadScriptFunc = async (src, props) => {
    try {
        return new Promise(resolve => {
            const script = document.createElement('script');
            script.src = src;
            const additionalScript = document.createElement('script');
            additionalScript.innerHTML = props;
            script.onload = () => {
                document.head.appendChild(additionalScript);
            };
            document.head.appendChild(script);
            resolve();
        });
    } catch (error) {
        console.error(error);
    }
};

/**
 * Set the cmsTicketId to the local storage
 */
export const setCmsTicketId = cmsTicketId => {
    localStorage.setItem(SMART_EDIT.CMS_TICKET_ID, cmsTicketId);
};

/**
 * Check if the application is loaded in the SmartEdit tool
 *
 */
export const isInSmartEdit = () => {
    return window !== window.parent;
};

export const formatHungaryTousends = input => {
    const country = getLocalization()[LOGIN_INFO.COUNTRY] || '';

    if (typeof input === 'string' && country === 'Hungary') {
        //changing the place of dot and comma in the numbers
        return input.replace(/,/gi, '!').replace(/\./gi, ',').replace(/!/gi, '.');
    }

    return input;
};

export const generateUUID = () => {
    if (!crypto?.getRandomValues) {
        const m = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
        let u = '';
        let i = 0;
        let rb = (Math.random() * 0xffffffff) | 0;
        while (i++ < 36) {
            const c = m[i - 1];
            const r = rb & 0xf;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            u += c === '-' || c === '4' ? c : v.toString(16);
            rb = i % 8 === 0 ? (Math.random() * 0xffffffff) | 0 : rb >> 4;
        }

        return u;
    }

    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
};

export const findComponent = (cmscomponents, uid) => {
    return cmscomponents.find(cmsComponent => cmsComponent.uid === uid);
};

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const initializeFullStory = state => {
    const siteId = getLocalization()[LOGIN_INFO.SITE_ID] || '';
    const fsConfig = _get(state, 'context.environment.fullStoryConfig', {});

    if (!fsConfig || !fsConfig.enabled || !fsConfig[siteId]?.enabled) return;

    FullStory.init({
        orgId: fsConfig.orgId
    });
};

export const removeDoubleQuotes = (str) => {
    if (typeof str !== 'string') {
        return str;
    }
    // Replace all occurrences of double quotes in the string
    return str.replace(/"/g, '');
}


export const parseDateString = dateString => {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
};

export const convertDateFormat = (dateString) => {
    const date = new Date(dateString);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}
