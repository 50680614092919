import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import Modal from "../../../core/Modal/Modal";
import {createPortal} from "react-dom";
import {CreditIssueMessage} from "../CreditIssueMessage/CreditIssueMessage";
import styles from "./CreditIssuePopup.pcss"
import {CreditIssueIcon} from "../Icon/CreditIssueIcon";
import {useDispatch} from "react-redux";
import {notificationRead} from "../../../../actions/CreditInfoAction";

export const PopupPortal = ({children}) => {
    const portalContainer = window.portalContainer || document.createElement("div");
    window.portalContainer = portalContainer;
    const mountNode = document.body;
    useEffect(() => {
        mountNode.appendChild(portalContainer);
        return () => {
            portalContainer.remove();
        }
    }, []);
    return createPortal(children, portalContainer);
};

export const Title = (props) => {
    const handleClick = (e) => {
        e.stopPropagation()
        props.onClose()
    }
    return <>
        <div className={styles.titleIconContainer}>
            <div className={styles.iconContainer}>
                <span className={styles.icon}>
                    <CreditIssueIcon width={21} height={21} />
                </span>
            </div>
            <span className={styles.title}>{props.text}</span>
            <span className={styles.spacer}></span>
            <button className={styles.modalCloseButton} onClick={handleClick}  type='button'>
                &times;
            </button>
        </div>
    </>
}

Title.propTypes = {
    onClose: PropTypes.func,
    text: PropTypes.string
}

export const CreditIssuePopup = (props) => {
    const dispatch = useDispatch();
    const {isOpen} = props;
    if (!isOpen) return <></>
    const onClose = () => {
        dispatch(notificationRead());
    }
    return (
        <PopupPortal>
            <Modal showModal={isOpen}
                   defaultCloseBtn={false}
                   title={<Title onClose={onClose} text={props.title}/>}
                   modalTheme={styles.popup}
                   modalTitleTheme={styles.titleContainer}
                   themeContent={styles.modalContent}
                   className={styles.overlay}
                   themeModalBlock={styles.modalBlock}
            >
                <CreditIssueMessage onClose={onClose} checkInvoicesButton={props.checkInvoicesButton} text={props.text} />
            </Modal>
        </PopupPortal>
    )
}

CreditIssuePopup.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    isOpen: PropTypes.bool,
    checkInvoicesButton: PropTypes.bool
}