/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';
import Slider from "react-slick";
import _get from 'lodash/get';
import _find from 'lodash/find';
import _cloneDeep from 'lodash/cloneDeep';
import classNames from 'classnames/bind';
import Image from '../../../components/core/Image/Image';
import Button from '../../../components/core/Button/Button';
import Reorder from '../../../components/site/Reorder/Reorder';
import Layout from '../../../components/site/Layout/Layout';
import Loader from '../../../components/site/Loader/Loader';
import SmartEdit from '../../../components/site/SmartEdit/SmartEdit';
import ProductCard from '../../../components/site/ProductCards/ProductCards';
import MarketingConsentPopup from '../../../components/core/MarketingConsentPopup/MarketingConsentPopup';
import SoftConsentModal  from '../../../components/core/SoftConsentModal/SoftConsentModal';
import ConsentBanner  from '../../../components/core/ConsentBanner/ConsentBanner';
import { getPageContent } from '../../../actions/PageContentAction';
import { getHomePageData } from '../../../actions/HomePageAction';
import { getCurrency, mainPartnerId, mainPartnerCode, isBdaaActive, getUserInfo, setUserInfo, getLocalization } from '../../../common/UserManager/UserManager';
import { formatHungaryTousends, getDecimal } from '../../../common/Utils/Utils';
import { 
    PAGE_ID,
    PLP_VIEW_TYPES,
    URL_PATHS,
    HOME_L10N,
    HOME_BANNER,
    UNIQUE_ID,
    GENERIC_L10N,
    DATA_LAYER_LISTS,
    GA_BANNERS_PROPS,
    LOGIN_INFO,
    MY_REWARDS
} from '../../../common/Constants';
import SlickCarousel from '../../../components/core/SlickCarousel/SlickCarousel';
import Icon from '../../../components/core/Icon/Icon';
import { viewItemListEventGA4, promotionImpressionEventGA4, promotionClickEventGA4 } from '../../../common/GoogleTagManager/GoogleTagManager';
import { productImpressionsDataLayerFillterGA4, homePromotionBannerImpressionsDataLayerFilterGA4 } from '../../../service/DataLayerFilter';
import { getBdaaSuggestion } from '../../../actions/ProductListAction';
import { getDevice, DEVICE_TYPES } from '../../../../__runtime/redux/ContextLoader';
import {openSubscriptionPopup} from "../../../actions/NotifyMeAction";
import NotificationSubscription from "../../../components/site/NotificationSubscription/NotificationSubscription";

import * as styles from './Home.css';
import OnboardingJourney from '../../../components/site/MiniCart/OnboardingJourney/OnboardingJourney';
import StockAllocation from '../../../components/site/StockAllocation/StockAllocation';
import LoyaltyPopUp from '../../../components/site/LoyaltyPopUp/LoyaltyPopUp';
import Pay4Performance from '../../../components/site/Pay4Performance/Pay4Performance';

const cx = classNames.bind(styles);

const settings = {
    className: "center",
    centerMode: false,
    dots: true, 
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    adaptiveHeight: false,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                nextArrow: <CustomNextArrow />,
                prevArrow: <CustomPrevArrow />,
            }
        },
        {
            breakpoint: 767,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            nextArrow: <CustomNextArrow hideComponent />,
            prevArrow: <CustomPrevArrow hideComponent />,
            }
        }
    ]
};

class Home extends Component {
    static propTypes = {
        homePageData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        isCustomerReport: PropTypes.bool
    };

    static defaultProps = {
        homePageData: {},
        isCustomerReport: false
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedView: PLP_VIEW_TYPES.GRID_VIEW,
            isListGrid: false,
            selectedMobileView: PLP_VIEW_TYPES.GRID_VIEW,
            isBdaaImpressionSent: false,
            softConsentPopupClosed: false,
        };
        props.actions.getPageContent({ pageId: PAGE_ID.HOME });
        props.actions.getHomePageData(true);

        this.smartOrdersSection = React.createRef();
        this.mainPartnerId = mainPartnerId();
		this.mainPartnerCode = mainPartnerCode();
        this.isBdaaActive = isBdaaActive();
    }

    componentDidUpdate = (prevProps) => {
        const { bannerImages } = this.props;

        if (prevProps.bannerImages.length !== bannerImages.length) {
            this.handleGAPromotionImpressions(this.prepareBannerImagesForCarousel(bannerImages), GA_BANNERS_PROPS.STANDARD);
            this.handleGAPromotionImpressions(this.prepareAdMiddleBanners(bannerImages), GA_BANNERS_PROPS.PROMOTIONS_AD_MIDDLE);
        }
    }

    componentWillUnmount = () => {
        if (getUserInfo().displayExplicitBanner === true) {
            this.hideSubscriptionBanner();
        }
        if (getUserInfo().displayExplicitPopup === true) {
            this.hideConsentPopUp();
        }
        if (getUserInfo().displaySoftConsentPopup === true) {
            this.hideSoftConsentPopup()
        }
        this.hideStockAllocationPopup()
    }

    hideSubscriptionBanner = () => {
        const userInfo = {...getUserInfo(), displayExplicitBanner: false};
        setUserInfo(userInfo);
    }

    hideConsentPopUp = () => {
        const userInfo = {...getUserInfo(), displayExplicitPopup: false};
        setUserInfo(userInfo);
    }

    hideSoftConsentPopup = () => {
        const userInfo = {...getUserInfo(), displaySoftConsentPopup: false};
        this.setState({ softConsentPopupClosed: true });
        setUserInfo(userInfo);
    }

    hideStockAllocationPopup = () => {
        const userInfo = {...getUserInfo(), displayStockAllocationPopup: false};
        setUserInfo(userInfo);
    }

    renderFavProducts = () => {
        const { localization, commonLocalization } = this.props;
        const favProducts = _get(this.props.homePageData, 'favProducts', []).slice(0, 4);

        try {
            viewItemListEventGA4(productImpressionsDataLayerFillterGA4(_cloneDeep(favProducts), DATA_LAYER_LISTS.HOMEPAGE_FAVORITE_LIST));
        } catch (ex) {
            console.error(ex);
        }
        
        const {blockAddToCartEnabled} = this.props.homePageData;

        return <ul className={cx('favProducts')}>{(favProducts || []).map((item, key) => (
            <ProductCard
                {...item}
                shipping={item.shippingInfo}
                unitOptions={item.units}
                url={item.productUrl}
                key={key.toString()}
                skuLabel={item.sku.label}
                skuId={item.sku.id}
                code={item.sku.code}
                isShowPrice={item.isFav}
                listPrice={item.sku.price&&item.sku.price.formattedPrice}
                addToCartLabel={localization[HOME_L10N.ADD_BTN]}
                isSelectViews={this.state.selectedView}
                isListGrid={this.state.isListGrid}
                isReturnEmpties={false}
                selectedMobileView={this.state.selectedMobileView}
                addToCartMessage={localization[GENERIC_L10N.ADD_TO_CART_MESSAGE]}
                addToCartNotifyButton={
                    commonLocalization?.[
                        GENERIC_L10N
                            .STOCK_NOTIFICATION
                            .NOTIFY_ME
                        ]
                }
                addToCartNotifiedButton={
                    commonLocalization?.[
                        GENERIC_L10N
                            .STOCK_NOTIFICATION
                            .GET_NOTIFIED
                        ]
                }
                maxQty={this.props.stockAllocation?.products.find(({product}) => product.codeShort === item.sku.id.toString())?.quantity}
                isFrom='HOME'
                blockAddToCartEnabled={blockAddToCartEnabled}
                openStockNotificationModal={() => this.props.actions.openSubscriptionPopup(item.sku.code)}
                recommendedQuantity={null}
                recommendedReason=''
            />
        ))}</ul>;
    };

    renderMyRecentOrder = () => {
        const { quickOrderList, localization } = this.props;

        return (quickOrderList || []).map(item => (
            <div className={cx('col4', 'sm12', 'md4', 'text-center', 'pad0', 'pad-l16')} key={item.orderId}>
                <div className={cx('recentOrderList')}>
                    <div className={cx('orderDate')}>{item.orderDate}</div>
                    <Link to={`${URL_PATHS.ORDER_DETAILS}/${item.orderId}`} className={cx('orderNum')}>
                        {item.orderId}
                    </Link>
                    {item.orderAmount !== undefined && (
                        <div className={cx('orderPrice')}>
                            <span>
                                {item.orderCurrency} {getDecimal(item.orderAmount)}
                            </span>
                        </div>
                    )}
                    <div className={cx('orderbtn')}>
                        <Reorder
                            orderId={item.orderId}
                            customReorderStyle={cx('reOrderBtn')}
                            buttonValue={localization[HOME_L10N.REORDER_BTN]}
                            automationId='at-reorder-button'
                            from='HOME'
                            themeAddCartIcon={cx('themeAddCartIcon')}
                            modalLocalization={{
                                cancelBtn: localization[HOME_L10N.REORDER_POPUP_CANCEL_BUTTON],
                                checkoutBtn: localization[HOME_L10N.REORDER_POPUP_CHECKOUT_BUTTON],
                                header: localization[HOME_L10N.REORDER_POPUP_HEADER_TEXT],
                                subtext: localization[HOME_L10N.REORDER_POPUP_SUBTEXT],
                                warning: localization[HOME_L10N.REORDER_POPUP_SUBTEXT_WARNING],
                            }}
                        />
                    </div>
                </div>
            </div>
        ));
    };

    renderFinancialInfo = () => {
        const { financialInfoData } = this.props;
        const financialInfo = _get(financialInfoData, 'financialInformationResult', []);
        const currency = getCurrency();

        return (
            <div className={cx('infReport')}>
                <h2>
                    <Icon
                        className={cx('leftCalIcon')}
                        width='24px'
                        height='32px'
                        viewBox='0 0 24 24'
                        name='financial-info'
                    />
                    {financialInfoData.title}
                </h2>

                {financialInfo.map(item => (
                    <div key={item.label} className={cx('clear-float', 'financeInfo')}>
                        <div className={cx('float-left', 'redwid')}> {item.label} </div>
                        <div className={cx('float-right')}>
                            {currency.symbol} {formatHungaryTousends(item.formattedValue)}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    renderReportCard = () => {
        const { customerReport, bannerImages } = this.props;
        const custReport = _get(customerReport, 'reports', []);
        const customerReportComponent = _find(bannerImages, [UNIQUE_ID, HOME_L10N.CUST_REPORT_COMP]);

        return (
            <SmartEdit data={customerReportComponent}>
            <div className={cx('reports')}>
                <h2>
                    <Icon
                        className={cx('leftCalIcon')}
                        width='27px'
                        height='32px'
                        viewBox='0 0 32 33'
                        name='custom-reports'
                    />
                    {customerReport.title}
                </h2>
                <ul className={cx('cusReport')}>
                    {custReport.map(item => (
                        <li key={item.label}>
                            <form action={item.link} method='post' target='_blank'>
                                <Button
                                    buttonId={item.label}
                                    type='submit'
                                    automationId='at-custom-reports-link'
                                    className={cx('customReportBtn')}
                                >
                                    {item.label}
                                    <span>
                                        <Icon
                                            className={cx('leftCalIcon')}
                                            iconType='svg'
                                            width='24px'
                                            height='24px'
                                            viewBox='0 0 24 24'
                                            name='right-arrow'
                                        />
                                    </span>
                                </Button>
                            </form>
                        </li>
                    ))}
                </ul>
            </div>
            </SmartEdit>
        );
    };

    handleGAPromotionImpressions = (items, blueprint) => {
        try {
            promotionImpressionEventGA4(homePromotionBannerImpressionsDataLayerFilterGA4(items, blueprint));
        } catch (ex) {
            console.error(ex);
        }
    }

    handleGAPromotionClick = (item, blueprint) => {
        try {
            promotionClickEventGA4(homePromotionBannerImpressionsDataLayerFilterGA4(item, blueprint));
        } catch (ex) {
            console.error(ex);
        }
    }

    prepareBannerImagesForCarousel = (bannerImages) => {
        const topBanner = _find(bannerImages, [UNIQUE_ID, HOME_BANNER.BANNER_UID]) || {};

        return _get(topBanner, 'childComponents', []).map((item, i) => ({
            id: i + 1,
            src: item.url,
            altText: item.altText,
            url: item.urlLink
        }));
    }

    prepareAdMiddleBanners = (bannerImages) => {
        const adMiddleBanner = _find(bannerImages, [UNIQUE_ID, HOME_BANNER.AD_IMAGES_UID]);
        return _get(adMiddleBanner, 'childComponents', [])
    }

    renderCarouselBanner = () => {
        /*Product banner image*/
        const { bannerImages, storifyMeConfig, deviceType } = this.props;
        const topBanner = _find(bannerImages, [UNIQUE_ID, HOME_BANNER.BANNER_UID]) || {};
        const images = this.prepareBannerImagesForCarousel(bannerImages);
        const siteId = getLocalization()[LOGIN_INFO.SITE_ID] || '';

        return (
            images.length > 0 && (
                <div automation-id='at-home-page-image-banner' className={cx('homePageConatiner')}>
                    <div className={cx('productBanner')}>
                        <SmartEdit data={topBanner}>
                            {deviceType?.isMobile && storifyMeConfig.enabledCountries.indexOf(siteId) !== -1 && (
                                <storifyme-collection url={storifyMeConfig.mobileWidget} />
                            )}
                            <SlickCarousel
                                handleGAPromotionClick={this.handleGAPromotionClick}
                                carouselData={images}
                                autoPlay
                            />
                        </SmartEdit>
                    </div>
                    {deviceType?.isDesktop && storifyMeConfig.enabledCountries.indexOf(siteId) !== -1 && (
                        <storifyme-collection url={storifyMeConfig.desktopWidget} />
                    )}
                </div>
            )
        );
    };

    renderAdBanner = () => {
        const { bannerImages } = this.props;
        const banners = this.prepareAdMiddleBanners(bannerImages);
        const adMiddleBanner = _find(bannerImages, [UNIQUE_ID, HOME_BANNER.AD_IMAGES_UID]);
        return (
            <SmartEdit data={adMiddleBanner}>
                <div className={cx('productBanner')}>
                    {banners.map(item => (
                        <div
                            onClick={() => this.handleGAPromotionClick(item, GA_BANNERS_PROPS.PROMOTIONS_AD_MIDDLE)}
                            key={item.url}
                            className={cx('col6', 'sm12', 'md6', 'text-center', 'pad0')}
                        >
                            <Image
                                automation-id='at-Promotion'
                                theme={cx('bannerImg')}
                                linkUrl={item.urlLink}
                                src={item.url}
                                alt={item.altText}
                            />
                        </div>
                    ))}
                </div>
            </SmartEdit>
        );
    };

    renderMinicartOnboardingJourney = () => {
        const { localization } = this.props;

        return (
            <OnboardingJourney
                modalLocalization={{
                    configureBtn: localization[HOME_L10N.MINICART_ONBOARDING_POPUP_CONFIGURE_BUTTON],
                    pic1line1: localization[HOME_L10N.MINICART_ONBOARDING_POPUP_FIRST_ICON_FIRST_TEXT_LINE],
                    pic1line2: localization[HOME_L10N.MINICART_ONBOARDING_POPUP_FIRST_ICON_SECOND_TEXT_LINE],
                    pic1line3: localization[HOME_L10N.MINICART_ONBOARDING_POPUP_FIRST_ICON_THIRD_TEXT_LINE],
                    pic1title: localization[HOME_L10N.MINICART_ONBOARDING_POPUP_FIRST_TITLE],
                    newLabel: localization[HOME_L10N.MINICART_ONBOARDING_POPUP_LABEL],
                    laterBtn: localization[HOME_L10N.MINICART_ONBOARDING_POPUP_MAYBE_LATER_BUTTON],
                    pic2line1: localization[HOME_L10N.MINICART_ONBOARDING_POPUP_SECOND_ICON_FIRST_TEXT_LINE],
                    pic2line2: localization[HOME_L10N.MINICART_ONBOARDING_POPUP_SECOND_ICON_SECOND_TEXT_LINE],
                    pic2line3: localization[HOME_L10N.MINICART_ONBOARDING_POPUP_SECOND_ICON_THIRD_TEXT_LINE],
                    pic2title: localization[HOME_L10N.MINICART_ONBOARDING_POPUP_SECOND_TITLE],

                }}
            />
        );
    };

    renderPay4Performance = () => {
        const { showOnboardingSelfREDPopUp, localization, commonLocalization } = this.props;
        return (
            <Pay4Performance 
                onboardingPopupPage1Title={localization?.[MY_REWARDS.ONBOARDING_POPUP_PAGE1_TITLE]}
                onboardingPopupPage1Description={localization?.[MY_REWARDS.ONBOARDING_POPUP_PAGE1_DESCRIPTION]}
                onboardingPopupPage2Title={localization?.[MY_REWARDS.ONBOARDING_POPUP_PAGE2_TITLE]}
                onboardingPopupPage2Description={localization?.[MY_REWARDS.ONBOARDING_POPUP_PAGE2_DESCRIPTION]}
                onboardingPopupPage3Title={localization?.[MY_REWARDS.ONBOARDING_POPUP_PAGE3_TITLE]}
                onboardingPopupPage3Description={localization?.[MY_REWARDS.ONBOARDING_POPUP_PAGE3_DESCRIPTION]}
                onboardingPopupPage4Title={commonLocalization?.[GENERIC_L10N.SELF_RED.ONBOARDING_POPUP_PAGE4_TITLE]}
                onboardingPopupPage4Description={commonLocalization?.[GENERIC_L10N.SELF_RED.ONBOARDING_POPUP_PAGE4_DESCRIPTION]}
                showOnboardingSelfREDPopUp={showOnboardingSelfREDPopUp}
                currentStepStart={0}
                setShowOnboardingSelfREDPopUp={() => {}}
            />
        )
    }

    renderStockAllocation = () => {
        const {
            localization
           
        } = this.props;
        return (
            <StockAllocation isFrom={PAGE_ID.HOME} title={localization?.[HOME_L10N.STOCK_ALLOCATION_TITLE]} subTitle={localization?.[HOME_L10N.STOCK_ALLOCATION_SUBTITLE]}/>
        )
    };


    scrollToSmartSection = () => {
        if ( !this.smartOrdersSection?.current?.scrollIntoView )  return
        this.smartOrdersSection.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }



    render() {

        const {
            homePageData,
            localization,
            quickOrderList,
            financialInfoData,
            customerReport,
            isCustomerReport,
            bdaaSuggestion,
            bannerImages,
            showMiniCartPreferences,
            miniCartMessageShown,
            stockAllocation,
            commonLocalization,
            showLoyaltyPopup
        } = this.props;
        const { isFavSpinner, isBottomSpinner } = homePageData.spinner || {};
        const bdaaText = this.props.bannerImages.filter(x => x.uid === 'homeBDAAParagraphComponent')[0];
        const bdaaImg = this.props.bannerImages.filter(x => x.uid === 'homeBDAARightSideBannerComponent')[0];
        const bdaaMsg = { 
            bdaaMsgBoldText: localization[HOME_L10N.BDAA_MSG_BOLD_TEXT],
            bdaaMsgRegularText: localization[HOME_L10N.BDAA_MSG_REGULAR_TEXT], 
            bdaaMsgLinkText: localization[HOME_L10N.BDAA_MSG_LINK_TEXT], 
            scrollToSmartSection: this.scrollToSmartSection
        };
        const userInfo = getUserInfo();

        if((this.props.isBdaaActive || this.isBdaaActive) &&!!bdaaSuggestion?.length && !this.state.isBdaaImpressionSent) {
            this.setState({ isBdaaImpressionSent: true }, () => {
                try {
                    viewItemListEventGA4(productImpressionsDataLayerFillterGA4(_cloneDeep(bdaaSuggestion), DATA_LAYER_LISTS.BDAA_LIST));
                } catch (err) {
                    console.error(err);
                }
            });
        }

        return (
            <Layout
                bdaaMsgHome={bdaaMsg}
                scrollToSmartSection={this.scrollToSmartSection}
                showDashboardHeader
                showOutlet
                theme={cx('homeContainer')}
                isBdaaActive={(this.props.isBdaaActive || this.isBdaaActive) && !!this.props.bdaaSuggestion?.length}
            >
                <NotificationSubscription />
                <LoyaltyPopUp
                    loyaltyPopupHeader1={commonLocalization?.loyaltyPopupHeader1}
                    loyaltyPopupHeader2={commonLocalization?.loyaltyPopupHeader2}
                    loyaltyPopupHeader3={commonLocalization?.loyaltyPopupHeader3}
                    loyaltyPopupText1={commonLocalization?.loyaltyPopupText1}
                    loyaltyPopupText2={commonLocalization?.loyaltyPopupText2}
                    loyaltyPopupText3={commonLocalization?.loyaltyPopupText3}
                    loyaltyPopupLearnMoreBtn={commonLocalization?.loyaltyPopupLearnMoreBtn}
                    isLoyaltyEnabled={showLoyaltyPopup}
                />
                {this.renderCarouselBanner()}

                {bannerImages.length > 0 &&
                    userInfo &&
                    Object.prototype.hasOwnProperty.call(userInfo, 'displaySoftConsentPopup') &&
                    userInfo.displaySoftConsentPopup && (
                        <SoftConsentModal
                            localization={localization}
                            bannerImages={bannerImages}
                            hideSoftConsentPopup={this.hideSoftConsentPopup}
                        />
                    )}
                {bannerImages.length > 0 && (
                    <MarketingConsentPopup
                        localization={localization}
                        bannerImages={bannerImages}
                        hideConsentPopUp={this.hideConsentPopUp}
                    />
                )}

                {/* Product Favorite Section */}
                {isFavSpinner ? (
                    <div className={cx('homeMiddlebox')}>
                        <div className={cx('favList', 'clear-float', 'spinnerList')}>
                            <Loader spinner loaderClass={cx('favOrderSpinner')} />
                        </div>
                    </div>
                ) : (
                    _get(homePageData, 'favProducts', []).length > 0 && (
                        <div className={cx('homeMiddlebox', 'homeFavMiddlebox')}>
                            <div className={cx('hmHeading')}>
                                <div className={cx('float-left', 'leftContent')}>
                                    <h2 automation-id='at-favourites-title-text'>
                                        {localization[HOME_L10N.FAV_PRODUCTS]}
                                    </h2>
                                </div>
                                {_get(homePageData, 'favProducts', []).length > 4 && (
                                    <Link
                                        to={URL_PATHS.PLP_ORDER_FAV}
                                        className={cx('float-right', 'brandcolor', 'borderLine')}
                                    >
                                        {localization[HOME_L10N.VIEW_ALL]}
                                    </Link>
                                )}
                            </div>
                            <div className={cx('favList', 'clear-float')}>{this.renderFavProducts()}</div>
                        </div>
                    )
                )}

                {/* Product ad middle banner section */}
                {this.renderAdBanner()}
                
                {miniCartMessageShown === false &&
                    showMiniCartPreferences === true &&
                        this.state.softConsentPopupClosed &&
                    this.renderMinicartOnboardingJourney()}

                {this.renderPay4Performance()}

                {/* Stock Allocation Popup section */}
                {userInfo.displayStockAllocationPopup !== false &&
                    stockAllocation?.showPopup &&
                    this.renderStockAllocation()}

                {/* Reccomended products section */}
                {(this.props.isBdaaActive || this.isBdaaActive) && !!this.props.bdaaSuggestion?.length && bdaaText && (
                    <div className={cx('bdaa-background')} ref={this.smartOrdersSection}>
                        <div className={cx('bdaa-component-wrapper')}>
                            <div className={cx('bdaa-text')} dangerouslySetInnerHTML={{ __html: bdaaText?.content }} />
                            <div className={cx('bdaa-img-wrapper')}>
                                <Image
                                    automation-id='at-bdaa-img'
                                    theme={cx('bdaa-img')}
                                    linkUrl={URL_PATHS.PLP_ORDER_RECOMMENDED}
                                    src={bdaaImg?.url}
                                    alt={bdaaImg?.altText}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {(this.props.isBdaaActive || this.isBdaaActive) && !!bdaaSuggestion?.length && (
                    <div className={cx('homeMiddlebox')}>
                        <div className={cx('hmHeading')}>
                            <div className={cx('float-left', 'leftContent')}>
                                <h2 automation-id='at-favourites-title-text'>
                                    {localization[HOME_L10N.BDAA_SMART_RECOMMENDATIONS]}
                                </h2>
                            </div>
                            <Link
                                to={URL_PATHS.PLP_ORDER_RECOMMENDED}
                                className={cx('float-right', 'brandcolor', 'borderLine', 'bdaa-plp-link')}
                            >
                                {localization[HOME_L10N.BDAA_VIEW_ALL_PRODUCTS]}
                            </Link>
                        </div>
                        <div className={cx('carousel-container', 'clear-float', 'bdaa-products-container')}>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                                {bdaaSuggestion.map((item, key) => (
                                    <ProductCard
                                        {...item}
                                        shipping={item.shippingInfo}
                                        url={item.productUrl}
                                        key={key.toString()}
                                        skuLabel={item.sku.label}
                                        skuId={item.id}
                                        code={item.code}
                                        unitOptions={item.units}
                                        addToCartLabel={localization[HOME_L10N.ADD_BTN]}
                                        isSelectViews={this.state.selectedView}
                                        isListGrid={this.state.isListGrid}
                                        isReturnEmpties={false}
                                        selectedMobileView={this.state.selectedMobileView}
                                        addToCartMessage={localization[GENERIC_L10N.ADD_TO_CART_MESSAGE]}
                                        maxQty={
                                            this.props.stockAllocation?.products.find(
                                                ({ product }) => product.codeShort === item.sku.id.toString()
                                            )?.quantity
                                        }
                                        isFrom='HOME'
                                        style={{ margin: '0 20x' }}
                                        isInBdaaSlider
                                        recommendedQuantity={item?.quantity || null}
                                        recommendedReason={item?.reason || ''}
                                    />
                                ))}
                            </Slider>
                        </div>
                    </div>
                )}

                {/* Product recent order sections */}
                <div className={cx('homeMiddlebox', 'marlf')}>
                    {quickOrderList.length > 0 && (
                        <>
                            <div className={cx('tpMarginhg')}>
                                <div className={cx('float-left', 'viewOrd')}>
                                    <h2>
                                        <Icon width='32px' height='32px' viewBox='0 0 32 32' name='order-history' />{' '}
                                        {localization[HOME_L10N.MY_RECENT_ORDER]}
                                    </h2>
                                    <div className={cx('ordSubtitle')}>{localization[HOME_L10N.VIEW_ORDER_HINT]}</div>
                                </div>
                                <Link
                                    to={URL_PATHS.ORDER_LIST}
                                    className={cx('float-right', 'brandcolor', 'borderLine', 'hiddenSm')}
                                >
                                    {localization[HOME_L10N.VIEW_ORDER_HISTORY]}
                                </Link>
                            </div>

                            <div className={cx('recentOrderbox', 'clear-float')}>
                                {this.renderMyRecentOrder()}
                                <div className={cx('padord')}>
                                    <Link
                                        to={URL_PATHS.ORDER_LIST}
                                        className={cx(
                                            'text-center',
                                            'brandcolor',
                                            'borderLine',
                                            'hiddenMd',
                                            'hiddenLg',
                                            'hiddenXl'
                                        )}
                                    >
                                        {localization[HOME_L10N.VIEW_ORDER_HISTORY]}
                                    </Link>
                                </div>
                            </div>
                        </>
                    )}

                    {isBottomSpinner ? (
                        <div className={cx('homeSpinner')}>
                            <Loader spinner loaderClass={cx('orderSpinner')} />
                        </div>
                    ) : (
                        <>
                            {/* Finance information and Customised Reports */}
                            {financialInfoData.isFinancialInfoVisible &&
                                _get(financialInfoData, 'financialInformationResult', []).length > 0 && (
                                    <div className={cx('col6', 'sm12', 'md12', 'pad0', 'pad-r-10', 'financeWrap')}>
                                        {this.renderFinancialInfo()}
                                    </div>
                                )}

                            {/* Customised Reports* */}
                            {isCustomerReport &&
                                customerReport.isReportSectionVisible &&
                                _get(customerReport, 'reports', []).length > 0 && (
                                    <div
                                        className={cx(
                                            'col6',
                                            'sm12',
                                            'md6',
                                            'pad0',
                                            'pad-l16',
                                            'hiddenMd',
                                            'hiddenSm',
                                            'financeWrap'
                                        )}
                                    >
                                        {this.renderReportCard()}
                                    </div>
                                )}
                        </>
                    )}
                </div>
                {bannerImages.length > 0 && (
                    <ConsentBanner
                        bannerImages={bannerImages}
                        localization={localization}
                        showBanner={this.state.showBanner}
                        hideSubscriptionBanner={this.hideSubscriptionBanner}
                    />
                )}
            </Layout>
        );
    }
}

function CustomNextArrow(props) {
	const { className, style, onClick, hideComponent } = props;

	const deviceClass = getDevice() === DEVICE_TYPES.MOBILE ? 'mobileArrowNext': 
						getDevice() === DEVICE_TYPES.TABLET ? 'tabletArrow':
						'desctopArrow';
	return (
		<div
		    className={cx(className, deviceClass, 'arrow', 'nextArrow', className.includes('disabled')?'disabled':'')}
            style={{ ...style, ...(hideComponent ? { display: 'none' } : {}) }}
		    onClick={onClick}>
			<Icon
				width='32px'
				height='32px'
				viewBox='0 0 24 24'
				name='chevron-right'
			/>
		</div>
	);
}
  
function CustomPrevArrow(props) {
    const { className, style, onClick, hideComponent } = props;

    const deviceClass = getDevice() === DEVICE_TYPES.MOBILE ? 'mobileArrowPrev': 
                        getDevice() === DEVICE_TYPES.TABLET ? 'tabletArrow':
                        'desctopArrow';
    return (
        <div
            className={cx(className, deviceClass, 'arrow', 'prevArrow', className.includes('disabled')?'disabled':'' )}
            style={{ ...style, ...(hideComponent ? { display: 'none' } : {}) }}
            onClick={onClick}
        >
            <Icon
                width='32px'
                height='32px'
                viewBox='0 0 24 24'
                name='chevron-left'
            />
        </div>
    );
}


const mapStateToProps = state => {
    const components = _get(state, 'pageContent.components', {});

    return {
        homePageData: _get(state, 'homePageData'),
        localization: _get(state, 'pageContent.localization'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`),
        stockAllocation: _get(state, 'pageContent.stockAllocation'),
        deviceType: _get(state, 'context.deviceType'),
        financialInfoData: _get(state, 'financialInfoData', {}),
        quickOrderList: _get(state, 'orderListData.quickOrders', []),
        customerReport: _get(state, 'customerReport', []),
        isCustomerReport: !!_find(components, [UNIQUE_ID, HOME_L10N.CUST_REPORT_COMP]),
        bannerImages: _get(state, 'pageContent.components', []),
        bdaaSuggestion: _get(state, 'products.bdaaSuggestion'),
        miniCartMessageShown: _get(state, 'loginPage.miniCartMessageShown'),
        showMiniCartPreferences: _get(state, 'loginPage.showMiniCartPreferences'),
        isBdaaActive: _get(state, 'products.isBdaaActive'),
        storifyMeConfig: _get(state, 'context.environment.storifyMe', {}),
        showLoyaltyPopup: _get(state, 'loginPage.showLoyaltyPopup'),
        showOnboardingSelfREDPopUp: _get(state, 'pageContent.showOnboardingSelfREDPopUp')
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPageContent,
            getHomePageData,
            getBdaaSuggestion,
            openSubscriptionPopup
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);